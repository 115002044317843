import { createRef, Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
	update,
	updateCargasList,
	updateProdutosList,
} from "../../redux/slicers/user";
import { clearPersistData } from "../../redux/storeConfig/store";
import {
	apenasNumeros,
	getAllData,
	getData,
	numerosDecimal3,
	numerosEditadoBR,
	updateTableNew,
	verificaTipoUsuario,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./Modal.scss";
import ModalEditarDimensaoProdutosCargas from "./ModalEditarDimensaoProdutosCargas";
import ModalCalculadora from "./ModalCalculadora";
import ModalErrorPretty from "./ModalErrorPretty";
import { isSuporte } from "../../services/helpers";

const nomeD = createRef(null);
const observacaoD = createRef(null);
const statusD = createRef(null);
const caminhaoD = createRef(null);
const rotaD = createRef(null);
const observacaoDelete = createRef(null);
const tipoCaminhaoD = createRef(null);

export default function ModalAtualizarCargas({
	msg,
	title,
	show,
	setShow,
	msgButton,
}) {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.userSlice);
	const caminhoes = useSelector((state) => state.userSlice.caminhoesList);
	const caminhoesFrete = useSelector((state) => state.userSlice.freteiroList);
	const caminhoesSelect = (placa) => caminhoes.data.find((item) => item.placa === placa);
	const caminhoesFreteSelect = (placa) => caminhoesFrete.data.find((item) => item.placa === placa);
	const rotas = useSelector((state) => state.userSlice.rotasList);
	const rotasSelect = useSelector((state) => state.userSlice.rotasList.data);
	const produtosList = useSelector((state) => state.userSlice.produtosList.data);
	const [caminhoesCaminhoneiro, setCaminhoesCaminhoneiro] = useState([])

	const cidades = (rota) =>
		rotasSelect
			.filter((item) => item.rota === rota)
			.map((item) => item.cidade)
			.filter((i, n, s) => s.indexOf(i) === n)
			.join(", ");

	const [produtoList, setProdutoList] = useState([]);
	const [carga, setCarga] = useState(msg.body);
	const [pesoTotal, setPesoTotal] = useState(0);
	const [caminhaoSelected, setCaminhaoSelected] = useState(
		msg.body.caminhao
			? caminhoesSelect(msg.body.caminhao) ||
					caminhoesFreteSelect(msg.body.caminhao)
			: {}
	);

	const [loading, setLoading] = useState({
		isLoading: false,
		msg: msgButton,
		style: "primary",
	});
	const [loadingClose, setLoadingClose] = useState({
		isLoading: false,
		msg: msgButton,
		style: "primary",
	});

	// #editar na producao para msg[12]
	
	const [caminhoesAdd, setCaminhoesAdd] = useState(
		msg.body.caminhao ? msg.body.caminhao.split(",") : []
	);
	

	const [error, setError] = useState({
		show: false,
		message: "",
	});
	

	
	const updateCargas = async () => {
		let id_filial = user.tipo !== "empresa" && user.id_filial != 2 ? user.id_filial : "";

		if (user.id_filial == 2) {
			id_filial = "";
		}

		console.log({ id_filial, logica: "lte", status: 12 })
		const cargasL = await getAllData( "cargas/em-processamento",{ id_filial, logica: "lte", status: 12 });
			// setCargas(cargasL.data)
		dispatch(updateCargasList(cargasL.data));
		setLoading(false)
		handlerSimpleClose();
	};

	const submitDataExclude = () => {
		setLoading({
			isLoading: true,
			msg: "Excluindo Carga",
			style: "danger",
		});
		msg.msg.motivo = observacaoDelete.current.value.length
			? observacaoDelete.current.value
			: "MOTIVO NÃO RELATADO";
		msg.msg.data_cancelamento = new Date().toISOString();
		msg.msg.status = 0;
		updateTableNew(`cargas?id=${carga.id}`, msg.msg)
			.then((resp) => {
				setLoading({
					isLoading: false,
					msg: "Excluido com Sucesso",
					style: "success",
				});

				updateCargas();
			})
			.catch((error) => {
				if (error.msg === "Unauthorized") {
					alert("Session has Expired");
					dispatch(update({ authenticated: false, token: "" }));
					window.location.replace("/login");
				}
			});
	};

	const submitDataEdit = async () => {
		setLoading({
			isLoading: true,
			msg: "Atualizando Carga",
			style: "warning",
		});

		let cargaToUpdate = {
			...carga,
			caminhao: caminhoesAdd.join(","),
		};
		
		if (tipoCaminhaoD.current.value == "Freteiro") {
			try {
				await updateTableNew(`cargas?id=${carga.id}`, {
					...cargaToUpdate,
					caminhoneiro: caminhaoSelected.motorista_cpf,
					tipo_transporte: "FRETE",
				});
				setLoading({
					isLoading: false,
					msg: "Atualizado com Sucesso",
					style: "success",
				});

				updateCargas();
			} catch (error) {
				if (error.msg.toLowerCase() === "unauthorized") {
					clearPersistData();
					// dispatch(update({ authenticated: false, token: '', foto_cnh: ''}))
					window.location.replace("/login");
				} else if (error.msg) {
					setError({show: true, message: error.message});
				}

				setLoading({
					isLoading: false,
					msg: msgButton,
					style: "danger",
				});
				return;
			} finally {
				return 
			}
		}

		const caminhoneiro = caminhoesCaminhoneiro.find(i => i.caminhao_placa === cargaToUpdate.caminhao);

		//caso o status seja menor que 11 (disponivel para entrega)
		if (caminhoneiro && cargaToUpdate.status < 11) {
			cargaToUpdate.caminhoneiro = caminhoneiro?.cpf_caminhoneiro ?? "";
		}
		if (!cargaToUpdate.caminhao) {
			cargaToUpdate.caminhoneiro = "";
		}
		if (cargaToUpdate.status == "11") {
			cargaToUpdate.data_chegada = null;
			cargaToUpdate.data_inicio_entrega = null;
		}
		
		console.log(cargaToUpdate)

		updateTableNew(`cargas?id=${carga.id}`, { ...cargaToUpdate, tipo_transporte: "PRÓPRIO" })
			.then((resp) => {
				setLoading({
					isLoading: false,
					msg: "Atualizado com Sucesso",
					style: "success",
				});

				updateCargas();
			})
			.catch((error) => {
				// let msg = null
				if (error.msg.toLowerCase() === "unauthorized") {
					clearPersistData();
					// dispatch(update({ authenticated: false, token: '', foto_cnh: ''}))
					window.location.replace("/login");
				} else if (error.msg) {
					setError({show: true, message: error.message});
				}

				setLoading({
					isLoading: false,
					msg: msgButton,
					style: "danger",
				});
			});
	};

	const onChangeInput = (e) => {
		e.preventDefault();

		if (caminhaoD.current.value.length) {
			if (tipoCaminhaoD.current?.value != "Freteiro") {
				setCaminhaoSelected(caminhoesSelect(caminhaoD.current.value));
			} else {
				setCaminhaoSelected(caminhoesFreteSelect(caminhaoD.current.value));
			}
		}
		if (e.target.name === "tipoCaminhao") {
			caminhaoD.current.value = "";
			setCaminhaoSelected({});
		}
		if (e.target.name === "tipo_carga") {
			setCarga((last) => ({ ...last, [e.target.name]: e.target.value }));
			return;
		}
		if (e.target.name === "regiao_entrega") {
			setCarga((last) => ({ ...last, [e.target.name]: e.target.value }));
			return;
		}
		if (e.target.name == "status" && e.target.value == "23") {
			setCarga((last) => ({
				...last,
				status: carga.regiao_entrega == "LOCAL" ? 15 : 13,
				data_chegada: new Date().toISOString(),
			}));
			return;
		}
		if (e.target.name == "status" && e.target.value == "24") {
			setCarga((last) => ({
				...last,
				status: 24,
				data_chegada: new Date().toISOString(),
			}));
			return;
		}

		setCarga((last) => ({
			...last,
			nome: nomeD.current.value.toUpperCase(),
			observacao: observacaoD.current.value.toUpperCase(),
			status: statusD.current.value,
			rota: rotaD.current.value,
		}));
	};

	const addCaminhaoToCarga = (e) => {
		e.preventDefault();
		const caminhaoSelected = caminhaoD.current.value;

		if (caminhoesAdd.length == 1) return setError({show: true, message: "Não é possivel ter mais de um caminhão para uma CARGA!"});
		if (carga.qtde_pedidos == 0) return setError({ show: true, children: <i style={{ fontSize: "1.2rem" }}>"Carga sem Pedidos! Adicione <b>pedidos</b> a carga para poder selecionar caminhões..."</i> })
		
		let dadosCaminhao
		
		if (tipoCaminhaoD.current.value == 'Freteiro') {
			dadosCaminhao = caminhoesFreteSelect(caminhaoSelected)
		} else {
			dadosCaminhao = caminhoesSelect(caminhaoSelected)
			const produtoExcedente = produtoList.find(produto => produto.comprimento > dadosCaminhao.comprimento)
			

			//regra de negocio para comparação de medidas
			if (produtoExcedente) return setError({
				show: true,
				children:
				<i style={{ fontSize: "1.2rem" }}>Produto <b style={{ fontSize: "1rem" }}>"{produtoExcedente.descricao}"</b> com comprimento de <b style={{ fontSize: "1rem" }}>"{produtoExcedente.comprimento}[m]"</b> excede o <b>COMPRIMENTO MÁXIMO</b> do caminhão de <b style={{ fontSize: "1rem" }}>"{dadosCaminhao.comprimento}[m]"</b></i>
			})

			//regra de negocio para comparação de peso
			const {pbt, tara} = dadosCaminhao
			if(pesoTotal > (pbt - tara) * 1000) return setError({
				show: true,
				children:
					<i style={{ fontSize: "1.2rem" }}>Peso total da carga
						<b style={{ fontSize: "1rem" }}>"{numerosEditadoBR(pesoTotal)}"</b>
						excede o peso limite do caminhão
						<b style={{ fontSize: "1rem" }}>"{numerosEditadoBR((pbt - tara) * 1000)}[kg]"</b>
						<br></br>
						<br></br>
						Calculo: PBT - Tara {">="} Peso da Carga
				
					</i>
			})
		}

			

		if (caminhoesAdd.indexOf(caminhaoSelected) < 0 && caminhaoSelected.length) {
			setCaminhoesAdd((last) => [...last, caminhaoSelected]);
		} else if (!caminhaoSelected.length) {
			
			return setError({show: true, message: "Selecione um caminhão."});
		} else {
			return setError({show: true, message: "Caminhão já selecionado. Tente outro caminhão"});
		}
		caminhaoD.current.value = "";
	};

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		setCaminhoesAdd((last) => [...last.filter((item) => item !== value)]);
	};

	const handlerSimpleClose = async () => {
		setShow((last) => ({ error: { flag: false } }));
	};

	const updateArrayEmbalagens = async (carga_id) => {
		try {
			let embalagens;
			if (user.tipo.toLowerCase() == "empresa") {
				embalagens = await getAllData(`embalagens/empresa/${carga_id}`);
			} else {
				embalagens = await getAllData("cargas/funcionarios/embalagens", {
					cargas_id: carga_id,
				});
			}

			return embalagens.data;
		} catch (error) {
			
			return setError({show: true, message: "Erro na obtenção das embalagens existentes"});
		}
	};

	const updateArrayProdutos = async (carga_id) => {
		try {
			const produtosGet = await getData(
				verificaTipoUsuario(user) + "planejamento/cargas/get-produtos",
				carga_id
			);
			return produtosGet.data;
		} catch (error) {
			
			return setError({show: true, message: "Erro na obtenção dos produtos"});
		}
	};

	const getMaiorProduto = async (carga_id) => {
		/**
		 * pegar todos os produtos da carga, com apenas o campo de descrição do produto e comprimento do produto
		 */
		const [produtosL, embalagensL] = await Promise.all([
			updateArrayProdutos(carga_id),
			updateArrayEmbalagens(carga_id),
		]);
		const produtosH = [...produtosL.filter((item) => !item.embalado)].map(
			(item) => ({ ...item, tipo: "PRODUTO" })
		);
		const embalagemsH = [...embalagensL].map((item) => ({
			...item,
			tipo: "EMBALAGEM",
			descricao: item.descricao.length
				? item.descricao
				: `EMBALAGEM N°${String(item.numero_embalagem).padStart(0, 6)}`,
			qtde: item.qtde_produtos,
		}));
		const result = [
			...produtosH.filter((item) => !item.embalado),
			...embalagemsH,
		];

		// const produtosGet =  await getData(verificaTipoUsuario(user) + 'planejamento/cargas/get-produtos', carga_id, {})
		const produto = result.sort((a, b) => {
			if (a.comprimento > b.comprimento) return -1;
			if (a.comprimento < b.comprimento) return 1;
			return 0;
		});

		const pesoTotal = result.reduce((p, c) => p + c.peso, 0);
		setProdutoList(result)

		dispatch(updateProdutosList(result));
		setPesoTotal(pesoTotal);
	};

	const getTrucks = async () => { 
		// getAllData('/caminhoes/caminhoneiros'),
		try {
			const [caminhoesCaminhoneiros] = await Promise.all([
				getAllData('caminhoes/caminhoneiros'),
			])
			setCaminhoesCaminhoneiro(caminhoesCaminhoneiros.data.filter(i => i.cpf_caminhoneiro))
		} catch (error) {
			console.dir(error)
		}
	}

	useEffect(() => {
		getTrucks()
		getMaiorProduto(msg.msg.pk);
	}, []);


	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{title}: {msg.msg.pk}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{title.includes("Excluir") ? <ExcluirDados msg={msg} /> : ""}
					{title.includes("Editar") ? (
						<>
							<Informacoes
								produto={produtosList}
								caminhao={caminhaoSelected}
								pesoTotal={pesoTotal}
								status={carga.status}
							/>
							<Form>
								<Row>
									<Col sm="12" lg="6" xl="8" className="mb-4">
										<Row className="mb-2">
											<Col sm="4">
												<Form.Label>Nome Carga:</Form.Label>
											</Col>
											<Col>
												<Form.Control
													type="text"
													placeholder={carga.nome}
													required
													value={carga.nome}
													ref={nomeD}
													onChange={onChangeInput}
												/>
											</Col>
										</Row>
										<Row className="mb-2">
											<Col sm="4">
												<Form.Label>Observação:</Form.Label>
											</Col>
											<Col>
												<Form.Control
													type="text"
													placeholder={carga.observacao}
													required
													value={carga.observacao}
													ref={observacaoD}
													onChange={onChangeInput}
												/>
											</Col>
										</Row>
										<Row className="mb-2">
											<Col sm="4">
												<Form.Label>Status:</Form.Label>
											</Col>
											<Col>
												<Form.Select
													type="text"
													ref={statusD}
													onChange={onChangeInput}
													value={carga.status}
													name="status"
													disabled={
														carga.status <= -2 &&
														caminhaoSelected?.placa == carga.caminhao
													}
												>
													<option value=""></option>
													<option value="0">INDISPONIVEL</option>
													<option value="1">DISPONIVEL PARA SEPARAÇÃO</option>
													<option value="3">DISPONIVEL PLANEJAMENTO</option>
													<option value="5">DISPONIVEL PARA FINANCEIRO</option>
													<option value="9">
														DISPONIVEL PARA CARREGAMENTO
													</option>
													<option value="11">DISPONIVEL PARA ENTREGA</option>
													{carga.tipo_transporte == "FRETE" ||
													carga.tipo_carga == "TRANSFERÊNCIA" ||
													carga.regiao_entrega == "LOCAL" ||
													carga.tipo_carga == "RETORNO" ? (
														<option value="24">FINALIZADO PELO GESTOR</option>
													) : (
														""
													)}
													{isSuporte(user.email) ? ( //id do suporte
														<>
															<option value=""></option>
															<option value="">SUPORTE</option>
															<option value="13">ENTREGUE REGIONAL</option>
															<option value="15">ENTREGUE LOCAL</option>
															<option value="23">ENTREGUE</option>
															<option value="24">FECHAMENTO FINANCEIRO</option>
														</>
													) : (
														""
													)}
												</Form.Select>
											</Col>
										</Row>
										<Row className="mb-2">
											<Col sm="4">
												<Form.Label>Tipo da Carga:</Form.Label>
											</Col>
											<Col>
												<Form.Select
													type="text"
													onChange={onChangeInput}
													name="tipo_carga"
													value={carga.tipo_carga}
												>
													<option value="PADRÃO">Padrão</option>
													<option value="TRANSFERÊNCIA">Transferência</option>
													<option value="RETORNO">Retorno</option>
												</Form.Select>
											</Col>
										</Row>
										<Row className="mb-2">
											<Col sm="4">
												<Form.Label>Tipo do Caminhão:</Form.Label>
											</Col>
											<Col>
												<Row>
													<Col col="8">
														<Form.Select
															type="text"
															ref={tipoCaminhaoD}
															onChange={onChangeInput}
															name="tipoCaminhao"
														>
															<option value=""></option>
															<option value="Toco">Toco</option>
															<option value="Truck">Truck</option>
															<option value="BiTruck">BiTruck</option>
															<option value="Carreta">Carreta</option>
															<option value="Freteiro">Caminhao Frete</option>
														</Form.Select>
													</Col>
												</Row>
											</Col>
										</Row>
										<Row className="mb-2">
											<Col sm="4">
												<Form.Label>Caminhão:</Form.Label>
											</Col>
											<Col>
												<Row>
													<Col col="8">
														<Form.Select
															type="text"
															ref={caminhaoD}
															onChange={onChangeInput}
														>
															<option value=""></option>
															{tipoCaminhaoD.current?.value != "Freteiro" &&
																caminhoes.data
																	.filter((item) => item.status == 1)
																	.filter((item) => {
																		return (
																			item.tipo.toLowerCase() ===
																			tipoCaminhaoD.current?.value.toLowerCase()
																		);
																	})
																	.map((item, index) => {
																		return (
																			<option key={index} value={item.placa}>
																				{item.placa}. P:{" "}
																				{numerosEditadoBR(
																					item?.pbt - item?.tara
																				)}{" "}
																				[Ton]; A:{" "}
																				{numerosEditadoBR(item?.altura)} [m]; C:{" "}
																				{numerosEditadoBR(item?.comprimento)}{" "}
																				[m]; L:{" "}
																				{numerosEditadoBR(item?.largura)} [m];
																			</option>
																		);
																	})}
															{tipoCaminhaoD.current?.value == "Freteiro" &&
																caminhoesFrete.data.map((item, index) => {
																	return (
																		<option key={index} value={item.placa}>
																			{item.tipo} - {item.placa} -{" "}
																			{item?.motorista_nome?.slice(0, 15)}...
																		</option>
																	);
																})}
														</Form.Select>
													</Col>
													<Col sm="4">
														<Button onClick={addCaminhaoToCarga}>Add</Button>
													</Col>
												</Row>
											</Col>
										</Row>
										{caminhoesAdd.length ? (
											<Row
												style={{ alignItems: "baseline", marginBottom: "10px" }}
											>
												<Col sm="4">Caminhoes:</Col>
												<Col sm="8">
													<div className="d-flex" style={{ overflow: "auto" }}>
														{caminhoesAdd.map((item, index) => (
															<span className="filters-card" key={index}>
																<span className="filters">
																	<span className="title">{item}</span>
																	<i
																		className="bi bi-x-lg"
																		onClick={onClickRemoveFilter}
																	></i>
																</span>
															</span>
														))}
													</div>
												</Col>
											</Row>
										) : (
											""
										)}
										<Row className="mb-2">
											<Col sm="4">
												<Form.Label>Rota:</Form.Label>
											</Col>
											<Col>
												<Form.Select
													type="text"
													ref={rotaD}
													onChange={onChangeInput}
													value={carga.rota}
												>
													<option value=""></option>
													{rotas.data
														.filter(
															(i, n, s) =>
																s.findIndex((o) => o.rota === i.rota) === n
														)
														.map((item, index) => (
															<option key={index} value={item.rota}>
																{item.rota}: {cidades(item.rota)}
															</option>
														))}
												</Form.Select>
											</Col>
										</Row>
										<Row className="mb-2">
											<Col sm="4">
												<Form.Label>Região de Entrega:</Form.Label>
											</Col>
											<Col>
												<Form.Select
													type="text"
													onChange={onChangeInput}
													value={carga.regiao_entrega}
													name="regiao_entrega"
												>
													<option value="LOCAL">Local</option>
													<option value="REGIONAL">Regional</option>
												</Form.Select>
											</Col>
										</Row>
									</Col>
									<Col sm="12" lg="6" xl="4">
										<Card
											className="card-dashboard"
											style={{ backgroundColor: "#F7D56C", padding: "10px" }}
										>
											<Card.Title className="text-center">Caminhão</Card.Title>
											<hr />
											<Row className="align-items-center">
												<Col sm="4">Placa:</Col>
												<Col>
													<p style={{ fontSize: "0.75rem", color: "inherit" }}>
														<b>{caminhaoSelected?.placa ?? "Selecione!"}</b>
													</p>
												</Col>
											</Row>
											<Row className="align-items-center">
												<Col sm="4">Dimensões:</Col>
												<Col>
													<p style={{ fontSize: "0.75rem", color: "inherit" }}>
														<b>
															C:{caminhaoSelected?.comprimento ?? 0} [m] | A:
															{caminhaoSelected?.altura ?? 0} [m] | L:
															{caminhaoSelected?.largura ?? 0} [m]
														</b>
													</p>
												</Col>
											</Row>
											<Row className="align-items-center">
												<Col sm="4">Capacidades:</Col>
												<Col>
													<p style={{ fontSize: "0.75rem", color: "inherit" }}>
														<b>
															PBT:
															{caminhaoSelected?.pbt
																? Number(caminhaoSelected?.pbt).toFixed(2)
																: 0.0}{" "}
															[T] | Tara:
															{caminhaoSelected?.tara
																? Number(caminhaoSelected?.tara).toFixed(2)
																: 0.0}{" "}
															[T] | Capac.:
															{caminhaoSelected?.capacidade
																? Number(caminhaoSelected?.capacidade).toFixed(
																		2
																  )
																: 0.0}{" "}
															[T]
														</b>
													</p>
												</Col>
											</Row>

											<hr />
											<p className="mt-3">
												Capacidade Total:{" "}
												{numerosEditadoBR(
													((caminhaoSelected?.pbt || 0) - (caminhaoSelected?.tara || 0)) * 1000
												)}{" "}
												[ kg ]
											</p>
											<p className="mt-3">
												Capacidade Restante:{" "}
												{
													numerosEditadoBR(
														((caminhaoSelected?.pbt || 0) - (caminhaoSelected?.tara || 0)) * 1000 -
															produtosList.reduce((p, c) => p + c.peso, 0)
													)}{" "}
												[ kg ]
											</p>
										</Card>
									</Col>
								</Row>
							</Form>
						</>
					) : (
						""
					)}
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								variant={loading.style}
								onClick={
									title.includes("Editar") ? submitDataEdit : submitDataExclude
								}
							>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
								{loading.style === "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								{loadingClose.isLoading ? (
									<Loading msg={loadingClose.msg} color={"black"} />
								) : (
									"Cancelar"
								)}
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
				{error.show ? ( <ModalErrorPretty {...error} setShow={setError} />) : ("")}
			</Modal>
		</>
	);
}

function ExcluirDados({ msg }) {
	return (
		<Fragment>
			<div>
				<Form>
					{Object.keys(msg.msg).map((key, index) => (
						<Row className="align-items-end" key={index}>
							<Col sm="3" className="mb-2">
								<Form.Label>{key.toUpperCase()}:</Form.Label>
							</Col>
							<Col className="mb-2">
								<Form.Control
									className="form-control-input"
									defaultValue={msg.msg[key]}
									disabled
								/>
							</Col>
						</Row>
					))}

					<Row className="align-items-end">
						<Col sm="3" className="mb-2">
							<Form.Label>MOTIVO CANC.:</Form.Label>
						</Col>
						<Col className="mb-2">
							<Form.Control
								required
								name="filterKeyword"
								className="form-control-input"
								placeholder="Digite o motivo do cancelamento"
								ref={observacaoDelete}
							/>
						</Col>
					</Row>
				</Form>
			</div>
		</Fragment>
	);
}

function Informacoes({ produto = [], caminhao, status }) {
	const [loading, setLoading] = useState(true);
	const [editarModal, setEditarModal] = useState({
		flag: false,
	});
	const [produtoEditado, setProdutoEditado] = useState({
		pedido: 0,
		qr_sku: 0,
		value: {
			old: 0,
			new: 0,
		},
	});
	const [showCalculator, setShowCalculator] = useState({
		flag: false,
		pedido: 0,
		qr_sku: 0,
	});

	const [error, setError] = useState({
		show: false,
		message: ''
	});

	const changeValue = (e, item, dimensao) => {
		if (item.tipo == "EMBALAGEM") {
			return setError({show: true, message: "Edição de Embalagem não habilitada"});
		}

		setProdutoEditado({
			pedido: item.numero_pedido,
			qr_sku: item.qr_sku, //
			value: {
				old: item[dimensao],
				new: numerosDecimal3(apenasNumeros(e.target.value)),
			},
			dimensao,
			cargas_id: item.cargas_id,
			title: "Atualizar " + dimensao.toUpperCase(),
			msg: `Confirmar Atualização de Produto "${
				item.descricao
			}":  Valor Antigo: ${
				produtoEditado.value.old
			} -> Valor Novo: ${numerosDecimal3(apenasNumeros(e.target.value))}`,
		});
	};

	const handleDivBlur = (event) => {
		if (produtoEditado.value.old == produtoEditado.value.new) return;
		setEditarModal({ produtoEditado, flag: true });
	};

	useEffect(() => {
		if (produto.length) setLoading(false);
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	}, [produto]);

	return (
		<div className="card-header mx-2">
			<h3 className="card-header">Informações da Carga</h3>
			<Row>
				<Col sm="12" lg="6" xl="4">
					<Card
						className="card-dashboard"
						style={{ backgroundColor: "#ece7e7", padding: "10px" }}
					>
						<Card.Title className="text-center">Maior Comprimento</Card.Title>
						<hr />
						<Row
							className="align-items-center"
							style={{ borderBottom: "solid 1px black" }}
						>
							<Col sm="2" style={{ fontSize: "0.8rem" }}>
								<b>QTDE</b>
							</Col>
							<Col sm="6" style={{ fontSize: "0.8rem" }}>
								<b>Nome</b>
							</Col>
							<Col sm="4" style={{ fontSize: "0.8rem", textAlign: "center" }}>
								<b>Comprimento [ m ]</b>
							</Col>
						</Row>
						<div
							style={{
								overflow: "hidden",
								overflowY: "auto",
								maxHeight: "200px",
							}}
						>
							{loading ? (
								<Fragment>
									<Row className="align-items-center mt-2">
										<Col sm="2">
											<div style={{ fontSize: "0.75rem", color: "inherit" }}>
												<Spinner animation="border" variant="dark" size="sm" />
											</div>
										</Col>
										<Col sm="6">
											<div style={{ fontSize: "0.75rem", color: "inherit" }}>
												<Spinner animation="border" variant="dark" size="sm" />
											</div>
										</Col>
										<Col sm="4">
											<div
												style={{
													fontSize: "0.75rem",
													color: "inherit",
													textAlign: "center",
												}}
											>
												<Spinner animation="border" variant="dark" size="sm" />
											</div>
										</Col>
									</Row>
								</Fragment>
							) : (
								""
							)}
							{produto.length &&
								[...produto]
									.sort((a, b) => {
										if (a.comprimento > b.comprimento) return 1;
										if (a.comprimento < b.comprimento) return -1;
										return 0;
									})
									.reverse()
									.map((item, key) => {
										return (
											<Fragment key={key}>
												<Row
													className="align-items-center py-1"
													style={{ borderBottom: "solid 1px black" }}
												>
													<Col sm="2">
														{loading ? (
															<div
																style={{
																	fontSize: "0.75rem",
																	color: "inherit",
																}}
															>
																<Spinner
																	animation="border"
																	variant="dark"
																	size="sm"
																/>
															</div>
														) : (
															<p
																style={{
																	fontSize: "0.75rem",
																	color: "inherit",
																	padding: "3px 0px",
																	borderRadius: "4px",
																	marginTop: "5px",
																}}
															>
																<b>{item?.qtde ?? 0}</b>
															</p>
														)}
													</Col>
													<Col sm="6">
														{loading ? (
															<div
																style={{
																	fontSize: "0.75rem",
																	color: "inherit",
																}}
															>
																<Spinner
																	animation="border"
																	variant="dark"
																	size="sm"
																/>
															</div>
														) : (
															<p
																style={{
																	fontSize: "0.75rem",
																	color: "inherit",
																	padding: "3px 0px",
																	borderRadius: "4px",
																	marginTop: "5px",
																}}
															>
																<b>{item?.descricao ?? "Sem Produtos"}</b>
															</p>
														)}
													</Col>
													<Col
														sm="4"
														className="d-flex align-items-center justify-content-center"
													>
														{loading ? (
															<div
																style={{
																	fontSize: "0.75rem",
																	color: "inherit",
																	textAlign: "center",
																}}
															>
																<Spinner
																	animation="border"
																	variant="dark"
																	size="sm"
																/>
															</div>
														) : (
															<input
																placeholder={
																	item.comprimento >= 0
																		? `${item.comprimento}`
																		: "Sem Produtos"
																}
																onChange={(e) =>
																	changeValue(e, item, "comprimento")
																}
																onBlur={(e) => handleDivBlur(e, item)}
																value={
																	produtoEditado.qr_sku === item.qr_sku &&
																	produtoEditado?.dimensao == "comprimento"
																		? produtoEditado.value.new
																		: Number(item.comprimento).toLocaleString(
																				"pt-BR"
																		  )
																}
																// value={ produtoEditado.qr_sku === item.qr_sku && produtoEditado?.dimensao == 'comprimento' ? (!produtoEditado.value.new ? produtoEditado.value.old : produtoEditado.value.new) : Number(item.comprimento).toLocaleString('pt-BR') }
																style={{
																	display: "flex",
																	width: "100px",
																	textAlign: "center",
																	cursor: "pointer",
																}}
																disabled={status === 0 ? false : true} // somente possibilitar edição em planejamento
															/>
														)}
													</Col>
												</Row>
											</Fragment>
										);
									})}
						</div>
					</Card>
				</Col>
				<Col sm="12" lg="6" xl="4">
					<Card
						className="card-dashboard"
						style={{ backgroundColor: "#ece7e7", padding: "10px" }}
					>
						<Card.Title className="text-center">Maior Peso</Card.Title>
						<hr />
						<p
							style={{
								textAlign: "center",
								marginBottom: "20px",
								color: "#000",
								fontWeight: "500",
							}}
						>
							Peso Total:{" "}
							{numerosEditadoBR(produto.reduce((p, c) => p + c.peso, 0))} [kg]
						</p>
						<Row
							className="align-items-center"
							style={{ borderBottom: "solid 1px black" }}
						>
							<Col sm="2" style={{ fontSize: "0.8rem" }}>
								<b>QTDE</b>
							</Col>
							<Col sm="6" style={{ fontSize: "0.8rem" }}>
								<b>Nome</b>
							</Col>
							<Col sm="4" style={{ fontSize: "0.8rem", textAlign: "center" }}>
								<b>Peso [ kg ]</b>
							</Col>
						</Row>
						<div
							style={{
								overflow: "hidden",
								overflowY: "auto",
								maxHeight: "200px",
							}}
						>
							{loading ? (
								<Fragment>
									<Row className="align-items-center mt-2">
										<Col sm="8">
											<div style={{ fontSize: "0.75rem", color: "inherit" }}>
												<Spinner animation="border" variant="dark" size="sm" />
											</div>
										</Col>
										<Col sm="4">
											<div
												style={{
													fontSize: "0.75rem",
													color: "inherit",
													textAlign: "center",
												}}
											>
												<Spinner animation="border" variant="dark" size="sm" />
											</div>
										</Col>
									</Row>
								</Fragment>
							) : (
								""
							)}
							{produto.length &&
								[...produto]
									.sort((a, b) => {
										if (a.peso > b.peso) return -1;
										if (a.peso < b.peso) return 1;
										return 0;
									})
									.map((item, key) => {
										return (
											<Fragment key={key}>
												<Row
													className="align-items-center py-1"
													style={{ borderBottom: "solid 1px black" }}
												>
													<Col sm="2">
														{loading ? (
															<div
																style={{
																	fontSize: "0.75rem",
																	color: "inherit",
																}}
															>
																<Spinner
																	animation="border"
																	variant="dark"
																	size="sm"
																/>
															</div>
														) : (
															<p
																style={{
																	fontSize: "0.75rem",
																	color: "inherit",
																	padding: "3px 0px",
																	borderRadius: "4px",
																	marginTop: "5px",
																}}
															>
																<b>{item?.qtde ?? 0}</b>
															</p>
														)}
													</Col>
													<Col sm="6">
														{loading ? (
															<div
																style={{
																	fontSize: "0.75rem",
																	color: "inherit",
																}}
															>
																<Spinner
																	animation="border"
																	variant="dark"
																	size="sm"
																/>
															</div>
														) : (
															<p
																style={{
																	fontSize: "0.75rem",
																	color: "inherit",
																	padding: "3px 0px",
																	borderRadius: "4px",
																	marginTop: "5px",
																}}
															>
																<b>{item?.descricao ?? "Sem Produtos"}</b>
															</p>
														)}
													</Col>
													<Col sm="4">
														{loading ? (
															<div
																style={{
																	fontSize: "0.75rem",
																	color: "inherit",
																	textAlign: "center",
																}}
															>
																<Spinner
																	animation="border"
																	variant="dark"
																	size="sm"
																/>
															</div>
														) : (
															<input
																placeholder={
																	item.peso >= 0
																		? `${item.peso}`
																		: "Sem Produtos"
																}
																onChange={(e) => changeValue(e, item, "peso")}
																onBlur={(e) => handleDivBlur(e, item)}
																value={
																	produtoEditado.qr_sku === item.qr_sku &&
																	produtoEditado?.dimensao == "peso"
																		? produtoEditado.value.new
																		: Number(item.peso).toLocaleString("pt-BR")
																}
																// value={produtoEditado.qr_sku === item.qr_sku && produtoEditado?.dimensao == 'peso'  ? (!produtoEditado.value.new ? produtoEditado.value.old : produtoEditado.value.new): Number(item.peso).toLocaleString('pt-BR')}
																style={{
																	display: "flex",
																	width: "100px",
																	textAlign: "center",
																	cursor: "pointer",
																}}
																disabled={status === 0 ? false : true} // somente possibilitar edição em planejamento
															/>
														)}
													</Col>
												</Row>
											</Fragment>
										);
									})}
						</div>
					</Card>
				</Col>
				<Col sm="12" lg="6" xl="4">
					<Card
						className="card-dashboard"
						style={{ backgroundColor: "#ece7e7", padding: "10px" }}
					>
						<Card.Title className="text-center">Maior Volume</Card.Title>
						<hr />
						<p
							style={{
								textAlign: "center",
								marginBottom: "20px",
								color: "#000",
								fontWeight: "500",
							}}
						>
							Volume Total:{" "}
							{numerosEditadoBR(
								produto.reduce((p, c) => p + Number(c?.volume) ?? 0, 0)
							)}{" "}
							[ m<sup>3</sup> ]
						</p>
						<Row
							className="align-items-center"
							style={{ borderBottom: "solid 1px black" }}
						>
							<Col sm="2" style={{ fontSize: "0.8rem" }}>
								<b>QTDE</b>
							</Col>
							<Col sm="6" style={{ fontSize: "0.8rem" }}>
								<b>Nome</b>
							</Col>
							<Col sm="4" style={{ fontSize: "0.8rem", textAlign: "center" }}>
								<b>
									Volume [ m<sup>3</sup> ]
								</b>
							</Col>
						</Row>
						<div
							style={{
								overflow: "hidden",
								overflowY: "auto",
								maxHeight: "200px",
							}}
						>
							{loading ? (
								<Fragment>
									<Row className="align-items-center mt-2">
										<Col sm="2">
											<div style={{ fontSize: "0.75rem", color: "inherit" }}>
												<Spinner animation="border" variant="dark" size="sm" />
											</div>
										</Col>
										<Col sm="6">
											<div style={{ fontSize: "0.75rem", color: "inherit" }}>
												<Spinner animation="border" variant="dark" size="sm" />
											</div>
										</Col>
										<Col sm="4">
											<div
												style={{
													fontSize: "0.75rem",
													color: "inherit",
													textAlign: "center",
												}}
											>
												<Spinner animation="border" variant="dark" size="sm" />
											</div>
										</Col>
									</Row>
								</Fragment>
							) : (
								""
							)}
							{produto.length &&
								[...produto]
									.sort((a, b) => {
										if (a.volume > b.volume) return 1;
										if (a.volume < b.volume) return -1;
										return 0;
									})
									.reverse()
									.map((item, key) => {
										return (
											<Fragment key={key}>
												<Row
													className="align-items-center my-1"
													style={{ borderBottom: "solid 1px black" }}
												>
													<Col sm="2">
														{loading ? (
															<div
																style={{
																	fontSize: "0.75rem",
																	color: "inherit",
																}}
															>
																<Spinner
																	animation="border"
																	variant="dark"
																	size="sm"
																/>
															</div>
														) : (
															<p
																style={{
																	fontSize: "0.75rem",
																	color: "inherit",
																	padding: "3px 0px",
																	borderRadius: "4px",
																	marginTop: "5px",
																}}
															>
																<b>{item?.qtde ?? 0}</b>
															</p>
														)}
													</Col>
													<Col sm="6">
														{loading ? (
															<div
																style={{
																	fontSize: "0.75rem",
																	color: "inherit",
																}}
															>
																<Spinner
																	animation="border"
																	variant="dark"
																	size="sm"
																/>
															</div>
														) : (
															<p
																style={{
																	fontSize: "0.75rem",
																	color: "inherit",
																	padding: "3px 0px",
																	borderRadius: "4px",
																	marginTop: "5px",
																}}
															>
																<b>{item?.descricao ?? "Sem Produtos"}</b>
															</p>
														)}
													</Col>
													<Col sm="4">
														{loading ? (
															<div
																style={{
																	fontSize: "0.75rem",
																	color: "inherit",
																	textAlign: "center",
																}}
															>
																<Spinner
																	animation="border"
																	variant="dark"
																	size="sm"
																/>
															</div>
														) : (
															<div className="div-calculator">
																<i
																	className="bi bi-calculator"
																	onClick={(e) =>
																		setShowCalculator({ flag: true, ...item })
																	}
																	hidden={status === 0 ? false : true}
																></i>
																<input
																	placeholder={
																		item.volume >= 0
																			? `${item.volume}`
																			: "Sem Produtos"
																	}
																	onChange={(e) =>
																		changeValue(e, item, "volume")
																	}
																	onBlur={(e) => handleDivBlur(e, item)}
																	value={
																		produtoEditado.qr_sku === item.qr_sku &&
																		produtoEditado?.dimensao == "volume"
																			? produtoEditado.value.new
																			: Number(item.volume).toLocaleString(
																					"pt-BR"
																			  )
																	}
																	// value={
																	//     produtoEditado.qr_sku === item.qr_sku && produtoEditado?.dimensao == 'volume' ?
																	//         (!produtoEditado.value.new ? produtoEditado.value.old
																	//             : produtoEditado.value.new) : Number(item.volume).toLocaleString('pt-BR')
																	// }
																	style={{
																		display: "flex",
																		width: "80px",
																		textAlign: "center",
																		cursor: "pointer",
																	}}
																	disabled={status === 0 ? false : true} // somente possibilitar edição em planejamento
																/>
															</div>
														)}
													</Col>
												</Row>
											</Fragment>
										);
									})}
						</div>
					</Card>
				</Col>
			</Row>
			{editarModal.flag ? (
				<ModalEditarDimensaoProdutosCargas
					title={produtoEditado.title}
					msg={produtoEditado.msg}
					show={editarModal.flag}
					setShow={setEditarModal}
					produtoEditado={editarModal.produtoEditado}
					setProduto={setProdutoEditado}
				/>
			) : (
				""
			)}
			{showCalculator.flag ? (
				<ModalCalculadora
					show={showCalculator.flag}
					setShow={setShowCalculator}
					{...showCalculator}
				/>
			) : (
				""
			)}
			{error.show ? ( <ModalErrorPretty {...error} setShow={setError} />) : ("")}
		</div>
	);
}
