import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	updateCargasList,
	updateFreteirosList,
	updateFuncionarios,
	updateRotasList,
} from "../../../redux/slicers/user";
import {
	getAllData,
	getDateInit,
	getFuncionarios,
	numerosEditadoBR,
	verificaTipoUsuario,
} from "../../../services/Servicos";
import Chart from "react-apexcharts";
import PedidosSemLogistica from "../indicadores/PedidosSemLogistica";
import ModalAtualizarDataEntradaVST from "../../modal/ModalAtualizarDataEntradaVST";
import GraficoPadraoComDataHorizontal from "../indicadores/GraficoPadraoComDataHorizontal";
import GraficoPadraoComDataHorizontalCaminhao from "../indicadores/GraficoPadraoComDataHorizontalCaminhao";
import GraficoPadraoCardsCaminhao from "../indicadores/GraficoPadraoCardsCaminhao";
import GraficoPadraoComDataHorizontalMotoristas from "../indicadores/GraficoPadraoComDataHorizontalMotoristas";
import GraficoTotalEntregueMotorista from "../indicadores/GraficoTotalEntregueMotorista";
import GraficoPadraoComDataHorizontal_2 from "../indicadores/GraficoPadraoComDataHorizontal_2";
import ModalFiltros from "../../modal/ModalFiltros";
import GraficoPadraoComDataVerticalCaminhao from "../indicadores/GraficoPadraoComDataVerticalCaminhao";
import GraficoPadraoComDataPieGastos from "../indicadores/GraficoPadraoComDataPieGastos";
import { unicos } from "../../../services/helpers";
import GraficoPadraoTopProdutos from "../indicadores/GraficoPadraoTopProdutos";
import GraficoDateLead from "../indicadores/GraficoDateLead";

export default function DashboardEmpresa({ setLoading = () => {}, loading }) {
	const user = useSelector((state) => state.userSlice);
	const caminhoes = user.caminhoesList;
	const funcionarios = user.funcionariosList;

	const [caminhaoSelecionado, setCaminhaoSelecionado] = useState(null);

	const [showFilters, setShowFilters] = useState({
		show: false,
	});
	const [fistRender, setFirstRender] = useState(true);

	const dispatch = useDispatch();
	const firstRender = async () => {
		const [funcionarios, fretes, rotas] = await Promise.all([
			getFuncionarios(user?.tipo),
			getAllData("caminhoes/freteiro"),
			getAllData(verificaTipoUsuario(user) + "rotas", {
				id_filial: user.tipo == "empresa" ? null : user.id_filial,
			}),
		]);
		dispatch(updateFuncionarios(funcionarios));
		dispatch(updateFreteirosList(fretes.data));
		dispatch(updateRotasList(rotas.data));
	};

	useLayoutEffect(() => {
		firstRender();
	}, []);

	const [cargas, setCargas] = useState({
		aSeparar: 0,
		aCarregar: 0,
		aEntregar: 0,
		aPlanejar: 0,
		emCarregamento: 0,
		entregue: 0,
	});

	const [dataParam, setDataParam] = useState({
		show: false,
		data_inicial: null,
		data_final: null,
		filtro: "Indicador de Pedidos",
	});

	const [dataParamMotoristas, setDataParamMotoristas] = useState({
		show: false,
		data_inicial: null,
		data_final: null,
		filtro: "Indicador dos Motoristas",
		title: "",
	});
	const [dataParamCaminhoes, setDataParamCaminhoes] = useState({
		show: false,
		data_inicial: null,
		data_final: null,
		filtro: "Indicador dos Caminhoes",
		title: "",
	});
	const [dataParamCaminhoes2, setDataParamCaminhoes2] = useState({
		show: false,
		data_inicial: null,
		data_final: null,
		filtro: "Indicador dos Caminhoes Pesos",
		title: "",
	});
	const [dataParamCaminhoes3, setDataParamCaminhoes3] = useState({
		show: false,
		data_inicial: null,
		data_final: null,
		filtro: "Indicador dos Caminhoes - Valores",
		title: "",
	});
	const [dataParamCaminhoes4, setDataParamCaminhoes4] = useState({
		show: false,
		data_inicial: null,
		data_final: null,
		filtro: "Indicador dos Caminhoes - clientes",
		title: "",
	});

	const [arrayFiltersN, setArrayFiltesN] = useState({
		caminhoes: [],
		caminhoes2: [],
		caminhoes3: [],
		caminhoes4: [],
		motoristas: [],
	});
	const [dataCargas, setDataCargas] = useState(null);
	const [dataMotoristas, setDataMotoristas] = useState(null);
	const [dataMotoristasFiltered, setDataMotoristasFiltered] = useState(null);

	useEffect(() => {
		setLoading(true);
		let datas = getDateInit(new Date());
		let data_inicial = datas.data_inicial;
		let data_final = datas.data_final;

		if (dataParam.data_final && dataParam.data_inicial) {
			data_inicial = dataParam.data_inicial;
			data_final = dataParam.data_final;
		}

		const [anoI, mesI, diaI] = data_inicial.split("-");
		const dataI = new Date(anoI, Number(mesI) - 1, diaI);
		const [anoF, mesF, diaF] = data_final.split("-");
		const dataF = new Date(anoF, Number(mesF) - 1, diaF);

		setDataCargas(
			`De ${dataI.toLocaleDateString()} Até ${dataF.toLocaleDateString()}`
		);
		getAllData("cargas/separador/empresa", { data_inicial, data_final }).then(
			(response) => {
				const cargasReceived = response.data;

				dispatch(
					updateCargasList(cargasReceived.filter((item) => item.status == 1))
				);

				const aSeparar = cargasReceived.filter(
					(item) => item.status == 1 || item.status == 2
				).length;
				const aPlanejar = cargasReceived.filter(
					(item) => item.status == 3 || item.status == 4
				).length;
				const aCarregar = cargasReceived.filter(
					(item) => item.status == 9
				).length;
				const emCarregamento = cargasReceived.filter(
					(item) => item.status == 10
				).length;
				const aEntregar = cargasReceived.filter(
					(item) => item.status == 11 || item.status == 12
				).length;
				const entregue = cargasReceived.filter(
					(item) => item.status > 12
				).length;
				setCargas((last) => ({
					...last,
					aSeparar,
					aCarregar,
					aEntregar,
					entregue,
					aPlanejar,
					emCarregamento,
				}));
				setLoading(false);
			}
		);
	}, [dataParam.data_final, dataParam.data_inicial]);

	useEffect(() => {
		let datas = getDateInit(new Date());
		let data_inicial = datas.data_inicial;
		let data_final = datas.data_final;

		if (dataParamMotoristas.data_final && dataParamMotoristas.data_inicial) {
			data_inicial = dataParamMotoristas.data_inicial;
			data_final = dataParamMotoristas.data_final;
		}
		getApiDataMotoristas(data_inicial, data_final);

		const [anoI, mesI, diaI] = data_inicial.split("-");
		const dataI = new Date(anoI, Number(mesI) - 1, diaI);
		const [anoF, mesF, diaF] = data_final.split("-");
		const dataF = new Date(anoF, Number(mesF) - 1, diaF);

		setDataMotoristas(
			`De ${dataI.toLocaleDateString()} Até ${dataF.toLocaleDateString()}`
		);
	}, [dataParamMotoristas.data_final, dataParamMotoristas.data_inicial]);

	const atualizarDataParametro = (data_inicial, data_final) => {
		setDataParam({ show: false, data_inicial, data_final });
		// setDataParamMotoristas({show: false, data_inicial, data_final})
	};

	const atualizarDataParametroMotorista = (data_inicial, data_final) => {
		setDataParamMotoristas((last) => ({
			...last,
			show: false,
			data_inicial,
			data_final,
		}));
		setFirstRender((last) => false);
	};

	const getApiDataMotoristas = async (data_inicial, data_final) => {
		let filial =
			user.tipo === "empresa" ? "" : `AND ID_FILIAL = ${user.id_filial}`;
		if (user.id_filial == 2) {
			filial = "";
		}

		const [anoI, mesI, diaI] = data_inicial.split("-");
		const dataI = new Date(anoI, Number(mesI) - 1, diaI);
		const [anoF, mesF, diaF] = data_final.split("-");
		const dataF = new Date(anoF, Number(mesF) - 1, diaF);

		setDataParam((last) => ({
			...last,
			title: `De ${dataI.toLocaleDateString()} Até ${dataF.toLocaleDateString()}`,
		}));

		const stringfilters = `${filial} AND CONVERT(CREATED_AT, DATE) >= '${data_inicial}' AND CONVERT(CREATED_AT, DATE) <= '${data_final}'`;
		getAllData("dashboard/empresa/get-totais-motoristas", { stringfilters })
			.then((resp) => {
				const resultado = resp.data;
				if (!arrayDataMotoristas.length) {
					setArrayDataMotoristas(resultado);
				} else {
					setArrayDataMotoristas(resultado);

					if (!firstRender) {
						setFirstRender(true);
						return;
					} else {
						setDataMotoristasFiltered(resultado);
						setArrayFiltesN((last) => ({ ...last, motoristas: [] }));
					}
				}
			})
			.catch((error) => {});
	};

	const [arrayDataCaminhoes, setArrayDataCaminhoes] = useState([]);
	const [dataCaminhoesFiltered, setDataCaminhoesFiltered] = useState(null);
	const atualizarDataParametroCaminhoes = (data_inicial, data_final) => {
		setDataParamCaminhoes((last) => ({
			...last,
			show: false,
			data_inicial,
			data_final,
		}));
		// setFirstRender((last) => false);
	};

	const getApiDataCaminhoes = async (data_inicial, data_final) => {
		let filial =
			user.tipo === "empresa" ? "" : `AND ID_FILIAL = ${user.id_filial}`;
		if (user.id_filial == 2) {
			filial = "";
		}

		const [anoI, mesI, diaI] = data_inicial.split("-");
		const dataI = new Date(anoI, Number(mesI) - 1, diaI);
		const [anoF, mesF, diaF] = data_final.split("-");
		const dataF = new Date(anoF, Number(mesF) - 1, diaF);

		setDataParamCaminhoes((last) => ({
			...last,
			title: `De ${dataI.toLocaleDateString()} Até ${dataF.toLocaleDateString()}`,
		}));

		const stringfilters = `${filial} AND CONVERT(CREATED_AT, DATE) >= '${data_inicial}' AND CONVERT(CREATED_AT, DATE) <= '${data_final}'`;
		getAllData("dashboard/empresa/get-total-gastos-caminhoes-caminhoneiros", {
			stringfilters,
		})
			.then((resp) => {
				const resultado = resp.data;
				setArrayDataCaminhoes(resultado);
			})
			.catch((error) => {});
	};

	useEffect(() => {
		let datas = getDateInit(new Date());
		let data_inicial = datas.data_inicial;
		let data_final = datas.data_final;

		if (dataParamCaminhoes.data_final && dataParamCaminhoes.data_inicial) {
			data_inicial = dataParamCaminhoes.data_inicial;
			data_final = dataParamCaminhoes.data_final;
		}
		getApiDataCaminhoes(data_inicial, data_final);
	}, [dataParamCaminhoes.data_final, dataParamCaminhoes.data_inicial]);

	const [arrayDataCaminhoes2, setArrayDataCaminhoes2] = useState([]);
	const [dataCaminhoesFiltered2, setDataCaminhoesFiltered2] = useState(null);

	const atualizarDataParametroCaminhoes2 = (data_inicial, data_final) => {
		setDataParamCaminhoes2((last) => ({
			...last,
			show: false,
			data_inicial,
			data_final,
		}));
		// setFirstRender((last) => false);
	};

	const getApiDataCaminhoes2 = async (data_inicial, data_final) => {
		let filial =
			user.tipo === "empresa" ? "" : `AND ID_FILIAL = ${user.id_filial}`;
		if (user.id_filial == 2) {
			filial = "";
		}

		const [anoI, mesI, diaI] = data_inicial.split("-");
		const dataI = new Date(anoI, Number(mesI) - 1, diaI);
		const [anoF, mesF, diaF] = data_final.split("-");
		const dataF = new Date(anoF, Number(mesF) - 1, diaF);

		setDataParamCaminhoes2((last) => ({
			...last,
			title: `De ${dataI.toLocaleDateString()} Até ${dataF.toLocaleDateString()}`,
		}));

		const stringfilters = `'${data_inicial}', '${data_final}'`;
		getAllData("dashboard/empresa/cargas-totais/", { stringfilters })
			.then((resp) => {
				const resultado = resp.data;
				setArrayDataCaminhoes2(resultado);
			})
			.catch((error) => {});
	};

	useEffect(() => {
		let datas = getDateInit(new Date());
		let data_inicial = datas.data_inicial;
		let data_final = datas.data_final;

		if (dataParamCaminhoes2.data_final && dataParamCaminhoes2.data_inicial) {
			data_inicial = dataParamCaminhoes2.data_inicial;
			data_final = dataParamCaminhoes2.data_final;
		}

		getApiDataCaminhoes2(data_inicial, data_final);
	}, [dataParamCaminhoes2.data_final, dataParamCaminhoes2.data_inicial]);

	const [arrayDataCaminhoes3, setArrayDataCaminhoes3] = useState([]);
	const [dataCaminhoesFiltered3, setDataCaminhoesFiltered3] = useState(null);

	const atualizarDataParametroCaminhoes3 = (data_inicial, data_final) => {
		setDataParamCaminhoes3((last) => ({
			...last,
			show: false,
			data_inicial,
			data_final,
		}));
		// setFirstRender((last) => false);
	};

	const getApiDataCaminhoes3 = async (data_inicial, data_final) => {
		let filial =
			user.tipo === "empresa" ? "" : `AND ID_FILIAL = ${user.id_filial}`;
		if (user.id_filial == 2) {
			filial = "";
		}

		const [anoI, mesI, diaI] = data_inicial.split("-");
		const dataI = new Date(anoI, Number(mesI) - 1, diaI);
		const [anoF, mesF, diaF] = data_final.split("-");
		const dataF = new Date(anoF, Number(mesF) - 1, diaF);

		setDataParamCaminhoes3((last) => ({
			...last,
			title: `De ${dataI.toLocaleDateString()} Até ${dataF.toLocaleDateString()}`,
		}));

		const stringfilters = `'${data_inicial}', '${data_final}'`;
		getAllData("dashboard/empresa/cargas-valores/", { stringfilters })
			.then((resp) => {
				const resultado = resp.data;
				setArrayDataCaminhoes3(resultado);
				// if (!arrayDataCaminhoes.length) {
				// } else {
				// 	// if (!firstRender) {
				// 	// 	setArrayDataCaminhoes(resultado);
				// 	// 	setFirstRender(true);
				// 	// }
				// 	// setDataCaminhoesFiltered(resultado);
				// 	setArrayFiltes([]);
				// }
			})
			.catch((error) => {});
	};

	useEffect(() => {
		let datas = getDateInit(new Date());
		let data_inicial = datas.data_inicial;
		let data_final = datas.data_final;

		if (dataParamCaminhoes3.data_final && dataParamCaminhoes3.data_inicial) {
			data_inicial = dataParamCaminhoes3.data_inicial;
			data_final = dataParamCaminhoes3.data_final;
		}

		getApiDataCaminhoes3(data_inicial, data_final);
	}, [dataParamCaminhoes3.data_final, dataParamCaminhoes3.data_inicial]);

	const [arrayDataList, setArrayDataList] = useState([]);
	const [arrayDataListFiltered, setArrayDataListFiltered] = useState(null);
	const [arrayDataCaminhoes4, setArrayDataCaminhoes4] = useState([]);
	const [dataCaminhoesFiltered4, setDataCaminhoesFiltered4] = useState(null);

	const atualizarDataParametroCaminhoes4 = (data_inicial, data_final) => {
		setDataParamCaminhoes4((last) => ({
			...last,
			show: false,
			data_inicial,
			data_final,
		}));
		// setFirstRender((last) => false);
	};

	const getApiDataCaminhoes4 = async (data_inicial, data_final) => {
		let filial =
			user.tipo === "empresa" ? "" : `AND ID_FILIAL = ${user.id_filial}`;
		if (user.id_filial == 2) {
			filial = "";
		}

		const [anoI, mesI, diaI] = data_inicial.split("-");
		const dataI = new Date(anoI, Number(mesI) - 1, diaI);
		const [anoF, mesF, diaF] = data_final.split("-");
		const dataF = new Date(anoF, Number(mesF) - 1, diaF);

		setDataParamCaminhoes4((last) => ({
			...last,
			title: `De ${dataI.toLocaleDateString()} Até ${dataF.toLocaleDateString()}`,
		}));

		const stringfilters = `'${data_inicial}', '${data_final}'`;
		getAllData("dashboard/empresa/clientes-caminhoes/", { stringfilters })
			.then((resp) => {
				let resultado = resp.data;
				if (user.tipo !== "empresa" || user.id_filial != 2) {
				} else {
					resultado = resultado.filter(
						(item) => item.id_filial == user.id_filial
					);
				}

				const placas = unicos(resultado, "placa", "id_filial");
				const resumo = placas.map((item) => ({
					id: item.placa,
					id_filial: item.id_filial,
					placa: item.placa,
					clientes: resultado.filter((i) => i.placa === item.placa).length,
					cargas_id: unicos(
						resultado.filter((i) => i.placa == item.placa),
						"cargas_id"
					).filter((item) => item.status_carga >= 13).length,
					cargas: unicos(
						resultado.filter((i) => i.placa == item.placa),
						"cargas_id"
					).filter((item) => item.status_carga >= 13),
				}));
				setArrayDataCaminhoes4(resumo);
				setArrayDataList(resultado);
			})
			.catch((error) => {});
	};

	useEffect(() => {
		if (!dataCaminhoesFiltered4) {
			return;
		}
		const filiais = dataCaminhoesFiltered4
			.filter((i, n, s) => s.findIndex((o) => o.id_filial == i.id_filial) === n)
			.map((item) => item.id_filial);
		if (filiais.length > 1) {
			setArrayDataListFiltered(arrayDataList);
		} else {
			const filial = dataCaminhoesFiltered4[0]?.id_filial;

			setArrayDataListFiltered(
				arrayDataList.filter((item) => item.id_filial == filial)
			);
		}
	}, [dataCaminhoesFiltered4]);

	useEffect(() => {
		let datas = getDateInit(new Date());
		let data_inicial = datas.data_inicial;
		let data_final = datas.data_final;

		if (dataParamCaminhoes4.data_final && dataParamCaminhoes4.data_inicial) {
			data_inicial = dataParamCaminhoes4.data_inicial;
			data_final = dataParamCaminhoes4.data_final;
		}

		getApiDataCaminhoes4(data_inicial, data_final);
	}, [dataParamCaminhoes4.data_final, dataParamCaminhoes4.data_inicial]);

	const [arrayDataMotoristas, setArrayDataMotoristas] = useState([]);
	const [options, setOptions] = useState({
		options: {
			chart: {
				id: "simple-bar",
			},
			grid: {
				show: false,
			},
			plotOptions: {
				bar: {
					horizontal: false,
					dataLabels: {
						position: "top",
						orientation: "vertical",
					},
				},
				dataLabels: {
					enabled: true,
					style: {
						colors: ["#333"],
					},
					// offsetX: 150
				},
			},
			dataLabels: {
				enabled: true,
				formatter: function (value, index) {
					return numerosEditadoBR(value);
					// return `${valorMoeda(value)}`;
				},
				offsetY: 10,
				style: {
					fontSize: "10px",
					colors: ["#304758"],
				},
				background: {
					enabled: true,
					foreColor: "#fff",
					padding: 4,
					borderRadius: 2,
					borderWidth: 1,
					borderColor: "#fff",
					opacity: 0.9,
					dropShadow: {
						enabled: false,
						top: 1,
						left: 1,
						blur: 1,
						color: "#000",
						opacity: 0.45,
					},
				},
			},
			xaxis: {
				labels: {
					style: {
						fontSize: "8px",
					},
				},
			},
			yaxis: {
				show: false,
				labels: {
					style: {
						fontSize: "8px",
					},
					formatter: (value, index) => {
						if (typeof value === "number") {
							return `${numerosEditadoBR(value)}`;
						}
						return value;
					},
				},
			},
		},
	});

	
	const [dadosCargas, setDadosCargas] = useState([]);
	const [dateCargas, setDateCargas] = useState('');
	const [dataParamCargas, setDataParamCargas] = useState({
		show: false,
		data_inicial: null,
		data_final: null,
		filtro: "Timeline Cargas",
		title: "",
	});

	const getCargas = async (data_inicial, data_final) => {
		let id_filial =
			user.tipo === "empresa" ? null : user.id_filial;
		
		if (user.id_filial == 2) {
			id_filial = null;
		}
			
		const stringfilters = { data_i: data_inicial, data_f: data_final, id_filial };
		
		const [anoI, mesI, diaI] = data_inicial.split("-");
		const dataI = new Date(anoI, Number(mesI) - 1, diaI);
		const [anoF, mesF, diaF] = data_final.split("-");
		const dataF = new Date(anoF, Number(mesF) - 1, diaF);

		// setDataInicialCargas(data_inicial)
		setDataParamCargas(last => ({
			...last,
			data_inicial,
			data_final,
			title: `De ${dataI.toLocaleDateString()} Até ${dataF.toLocaleDateString()}` 
			
		}));
		try {
			const { data } = await getAllData("dashboard/empresa/cargas/", { stringfilters })
			setDadosCargas(data)
		} catch (error) {
			console.log(error)
		}
	};


	useEffect(() => {
		let datas = getDateInit(new Date());
		let data_inicial = datas.data_inicial;
		let data_final = datas.data_final;

		if (dataParamCaminhoes4.data_final && dataParamCaminhoes4.data_inicial) {
			data_inicial = dataParamCaminhoes4.data_inicial;
			data_final = dataParamCaminhoes4.data_final;
		}

		getCargas(data_inicial, data_final);
	}, []);

	return (
		<Fragment>
			<Row>
				<Col sm="12">
					<div className="card-header">
						<h5>Cadastros</h5>
					</div>
				</Col>
				<Col sm="6" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-1"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								caminhoes.data.length
							)}
						</h3>
						<p>Caminhões Cadastrados</p>
						<i className="bi bi-truck bi-dashboard"></i>
					</Card>
				</Col>
				<Col sm="6" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-2"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								funcionarios.data.length
							)}
						</h3>
						<p>Funcionários Cadastrados</p>
						<i className="bi bi-people bi-dashboard"></i>
					</Card>
				</Col>

				<Col sm="6" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-3"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								caminhoes.data.filter((item) => item.status).length
							)}
						</h3>
						<p>Caminhões Ativos</p>
						<i className="bi bi-truck bi-dashboard"></i>
					</Card>
				</Col>
				<Col sm="6" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-4"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								funcionarios.data.filter((item) => item.status).length
							)}
						</h3>
						<p>Funcionários Ativos</p>
						<i className="bi bi-people bi-dashboard"></i>
					</Card>
				</Col>
			</Row>
			{/* dashboard das cargas */}

			<Row>
				<Col sm="12">
					<div className="card-header d-flex pb-2">
						<h5 className="">Cargas</h5>
						<span
							className="filter-data"
							onClick={() => setDataParam((last) => ({ ...last, show: true }))}
						>
							Filtrar Data
							<span className="filho">{dataCargas}</span>
							<i
								className="bi bi-calendar-date"
								style={{ marginLeft: "5px" }}
							></i>
						</span>
					</div>
				</Col>
				<Col sm="12" md="4" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-1"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								cargas.aSeparar
							)}
						</h3>
						<p>A Separar </p>
						<i className="bi bi-exclamation-triangle bi-dashboard"></i>
					</Card>
				</Col>
				<Col sm="12" md="4" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-2"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								cargas.aPlanejar
							)}
						</h3>
						<p>A Planejar</p>
						<i className="bi bi-tools seam bi-dashboard"></i>
					</Card>
				</Col>
				<Col sm="12" md="4" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-3"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								cargas.aCarregar
							)}
						</h3>
						<p>A Carregar</p>
						<i className="bi bi-box-seam bi-dashboard"></i>
					</Card>
				</Col>
				<Col sm="12" md="4" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-2"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								cargas.emCarregamento
							)}
						</h3>
						<p>Carregamento Parcial</p>
						<i className="bi bi-box-seam bi-dashboard"></i>
					</Card>
				</Col>
				<Col sm="12" md="4" lg="3">
					<Card
						style={{ width: "auto" }}
						className="card-dashboard card-color-4"
					>
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								cargas.aEntregar
							)}
						</h3>
						<p>A Entregar</p>
						<i className="bi bi-info-circle bi-dashboard"></i>
					</Card>
				</Col>
				<Col sm="12" md="4" lg="3">
					<Card style={{ width: "auto" }} className="card-dashboard A">
						<h3>
							{loading ? (
								<Spinner animation="grow" variant="primary" />
							) : (
								cargas.entregue
							)}
						</h3>
						<p>Entregue</p>
						<i className="bi bi-check-lg bi-dashboard"></i>
					</Card>
				</Col>
			</Row>

			{/* teste de novos indicadores */}
			<Row>
				<Col sm="12">
					<PedidosSemLogistica dataParam={dataParam} />
				</Col>
			</Row>
			<Row>
				<Col sm="12" md="6" lg="6">
					<GraficoPadraoComDataHorizontal
						url="dashboard/empresa/cargas/filiais/total-peso"
						title="Peso por Filial"
						unidadeMedida="kg"
						posisaoUnidadeMedida={"atras"}
						parametroGrafico={"total_peso"}
						colors="#012974"
						dataParam={dataParam}
					/>
				</Col>
				<Col sm="12" md="6" lg="6">
					<Row>
						<Col sm="12" md="6">
							<GraficoPadraoComDataHorizontal_2
								url="dashboard/empresa/get-total-comprimento-entregue"
								title={`Metros Quadrados`}
								unidadeMedida={``} //"m2"
								posisaoUnidadeMedida={"atras"}
								parametroGrafico={"total_comprimento"}
								colors="#ff0000"
								dataParam={dataParam}
							/>
						</Col>
						<Col sm="12" md="6">
							<GraficoPadraoComDataHorizontal_2
								url="dashboard/empresa/get-total-despesas"
								title="Despesas"
								unidadeMedida="R$"
								posisaoUnidadeMedida={"frente"}
								parametroGrafico={"total_despesas"}
								colors="#00ff00"
								dataParam={dataParam}
							/>
						</Col>
						<Col sm="12" md="6">
							<GraficoPadraoComDataHorizontal_2
								url="dashboard/empresa/get-total-combustivel"
								title="Combustível"
								unidadeMedida="R$"
								posisaoUnidadeMedida={"frente"}
								parametroGrafico={"valor_total"}
								colors="#0000ff"
								dataParam={dataParam}
							/>
						</Col>
					</Row>
				</Col>
			</Row>

			<Row className="mt-4">
				<Col sm="12">
					<div className="card-header d-flex pb-2 justify-content-end">
						<h5 className="">Gastos dos Caminhões</h5>
						<span
							className="filter-data"
							onClick={() =>
								setDataParamCaminhoes((last) => ({ ...last, show: true }))
							}
						>
							Filtrar Data
							<span className="filho">{dataParamCaminhoes.title}</span>
							<i
								className="bi bi-calendar-date"
								style={{ marginLeft: "5px" }}
							></i>
						</span>
						<span
							className="dashboard-filters"
							onClick={() => {
								setShowFilters((last) => ({
									...last,
									show: true,
									arrayData: arrayDataCaminhoes,
									setFilteredTable: setDataCaminhoesFiltered,
									variable: "caminhoes",
									filterOptions: [
										"id_filial",
										"caminhoneiro",
										"tipo",
										"tipo_alimentacao",
									],
								}));
							}}
						>
							Filtros
							<i className="bi bi-gear" style={{ marginLeft: "5px" }}></i>
						</span>
					</div>
				</Col>
			</Row>

			<Row>
				<Col sm="12" md="6">
					<GraficoPadraoComDataHorizontalCaminhao
						url="dashboard/empresa/get-total-gastos-caminhoes-caminhoneiros"
						title="Total de Gasto por Caminhao"
						unidadeMedida="R$"
						posisaoUnidadeMedida={"frente"}
						parametroGrafico={"valor"}
						colors="#2F9E96"
						setCaminhaoSelecionado={setCaminhaoSelecionado}
						dataParam={dataParam}
						arrayData={arrayDataCaminhoes}
						dataFiltered={dataCaminhoesFiltered}
					/>
				</Col>
				<Col sm="12" md="6">
					<GraficoPadraoCardsCaminhao
						url="dashboard/empresa/get-total-gastos-caminhoes-caminhoneiros"
						title="Total de Gasto"
						unidadeMedida="R$"
						posisaoUnidadeMedida={"frente"}
						parametroGrafico={"valor"}
						colors="#2F9E96"
						setCaminhaoSelecionado={setCaminhaoSelecionado}
						caminhaoSelecionado={caminhaoSelecionado}
						dataParam={dataParam}
						arrayData={arrayDataCaminhoes}
						dataFiltered={dataCaminhoesFiltered}
					/>
				</Col>
			</Row>

			{/* caminhoes parte 2 */}
			<Row className="mt-4">
				<Col sm="12">
					<div className="card-header d-flex pb-2 justify-content-end">
						<h5 className="">Informações dos Caminhões</h5>
						<span
							className="filter-data"
							onClick={() =>
								setDataParamCaminhoes2((last) => ({ ...last, show: true }))
							}
						>
							Filtrar Data
							<span className="filho">{dataParamCaminhoes2.title}</span>
							<i
								className="bi bi-calendar-date"
								style={{ marginLeft: "5px" }}
							></i>
						</span>
						<span
							className="dashboard-filters"
							onClick={() => {
								setShowFilters((last) => ({
									...last,
									show: true,
									arrayData: arrayDataCaminhoes2,
									setFilteredTable: setDataCaminhoesFiltered2,
									variable: "caminhoes2",
									filterOptions: ["id_filial"],
								}));
							}}
						>
							Filtros
							<i className="bi bi-gear" style={{ marginLeft: "5px" }}></i>
						</span>
					</div>
				</Col>
			</Row>
			<Row>
				<Col sm="12" md="6">
					<GraficoPadraoComDataVerticalCaminhao
						title="Média de Volume por Caminhão"
						unidadeMedida="m3"
						posisaoUnidadeMedida={"atras"}
						parametroGrafico={"media_volume"}
						colors="#bF9E96"
						arrayData={arrayDataCaminhoes2}
						dataFiltered={dataCaminhoesFiltered2}
					/>
				</Col>
				<Col sm="12" md="6">
					<GraficoPadraoComDataVerticalCaminhao
						title="Total de Volume por Caminhão"
						unidadeMedida="m3"
						posisaoUnidadeMedida={"atras"}
						parametroGrafico={"total_volume"}
						colors="#E09C26"
						arrayData={arrayDataCaminhoes2}
						dataFiltered={dataCaminhoesFiltered2}
					/>
				</Col>
				<Col sm="12" md="6">
					<GraficoPadraoComDataVerticalCaminhao
						title="Média de Peso por Caminhão"
						unidadeMedida="kg"
						posisaoUnidadeMedida={"atras"}
						parametroGrafico={"media_peso"}
						colors="#36476C"
						arrayData={arrayDataCaminhoes2}
						dataFiltered={dataCaminhoesFiltered2}
					/>
				</Col>
				<Col sm="12" md="6">
					<GraficoPadraoComDataVerticalCaminhao
						title="Total de Peso por Caminhão"
						unidadeMedida="kg"
						posisaoUnidadeMedida={"atras"}
						parametroGrafico={"total_peso"}
						colors="#36476C"
						arrayData={arrayDataCaminhoes2}
						dataFiltered={dataCaminhoesFiltered2}
					/>
				</Col>
				<Col sm="12" md="6">
					<GraficoPadraoComDataVerticalCaminhao
						title="Média de Pedidos por Caminhão"
						unidadeMedida=""
						posisaoUnidadeMedida={"atras"}
						parametroGrafico={"media_pedidos"}
						colors="#FF5234"
						arrayData={arrayDataCaminhoes2}
						dataFiltered={dataCaminhoesFiltered2}
					/>
				</Col>
				<Col sm="12" md="6">
					<GraficoPadraoComDataVerticalCaminhao
						title="Média de Produtos por Caminhão"
						unidadeMedida=""
						posisaoUnidadeMedida={"atras"}
						parametroGrafico={"media_produtos"}
						colors="#20918D"
						arrayData={arrayDataCaminhoes2}
						dataFiltered={dataCaminhoesFiltered2}
					/>
				</Col>
			</Row>

			{/* caminhoes parte 3 */}
			<Row className="mt-4">
				<Col sm="12">
					<div className="card-header d-flex pb-2 justify-content-end">
						<h5 className="">Clientes por Caminhão</h5>
						<span
							className="filter-data"
							onClick={() =>
								setDataParamCaminhoes4((last) => ({ ...last, show: true }))
							}
						>
							Filtrar Data
							<span className="filho">{dataParamCaminhoes4.title}</span>
							<i
								className="bi bi-calendar-date"
								style={{ marginLeft: "5px" }}
							></i>
						</span>
						<span
							className="dashboard-filters"
							onClick={() => {
								setShowFilters((last) => ({
									...last,
									show: true,
									arrayData: arrayDataCaminhoes4,
									setFilteredTable: setDataCaminhoesFiltered4,
									variable: "caminhoes4",
									filterOptions: ["id_filial"],
								}));
							}}
						>
							Filtros
							<i className="bi bi-gear" style={{ marginLeft: "5px" }}></i>
						</span>
					</div>
				</Col>
			</Row>
			<Row>
				<Col sm="12" md="6">
					<GraficoPadraoComDataVerticalCaminhao
						title="Clientes por Caminhão"
						unidadeMedida=""
						posisaoUnidadeMedida={"atras"}
						parametroGrafico={"clientes"}
						colors="#83533A"
						arrayData={arrayDataCaminhoes4}
						dataFiltered={dataCaminhoesFiltered4}
					/>
					<GraficoPadraoComDataVerticalCaminhao
						title="Cargas Entregues por Caminhão"
						unidadeMedida=""
						posisaoUnidadeMedida={"atras"}
						parametroGrafico={"cargas_id"}
						colors="#118db5"
						arrayData={arrayDataCaminhoes4}
						dataFiltered={dataCaminhoesFiltered4}
					/>
				</Col>
				<Col sm="12" md="6">
					<GraficoPadraoTopProdutos
						title="Top 15 Clientes"
						parametroGrafico={"pedidos"}
						arrayData={arrayDataList}
						dataFiltered={arrayDataListFiltered}
					/>
				</Col>
			</Row>

			<Row className="mt-4">
				<Col sm="12">
					<div className="card-header d-flex pb-2 justify-content-end">
						<h5 className="">Valores Carregados por Caminhões</h5>
						<span
							className="filter-data"
							onClick={() =>
								setDataParamCaminhoes3((last) => ({ ...last, show: true }))
							}
						>
							Filtrar Data
							<span className="filho">{dataParamCaminhoes3.title}</span>
							<i
								className="bi bi-calendar-date"
								style={{ marginLeft: "5px" }}
							></i>
						</span>
						<span
							className="dashboard-filters"
							onClick={() => {
								setShowFilters((last) => ({
									...last,
									show: true,
									arrayData: arrayDataCaminhoes3,
									setFilteredTable: setDataCaminhoesFiltered3,
									variable: "caminhoes3",
									filterOptions: ["id_filial"],
								}));
							}}
						>
							Filtros
							<i className="bi bi-gear" style={{ marginLeft: "5px" }}></i>
						</span>
					</div>
				</Col>
				<Col sm="12" md="6">
					<GraficoPadraoComDataPieGastos
						title="Média de Valores por Caminhão"
						unidadeMedida=""
						posisaoUnidadeMedida={"atras"}
						parametroGrafico={"media_valor"}
						colors="#20918D"
						arrayData={arrayDataCaminhoes3}
						dataFiltered={dataCaminhoesFiltered3}
					/>
				</Col>
				<Col sm="12" md="6">
					<GraficoPadraoComDataPieGastos
						title="Total de Valores por Caminhão"
						unidadeMedida=""
						posisaoUnidadeMedida={"atras"}
						parametroGrafico={"valor_total"}
						colors="#20918D"
						arrayData={arrayDataCaminhoes3}
						dataFiltered={dataCaminhoesFiltered3}
					/>
				</Col>
			</Row>

			<Row className="mt-4">
				<Col sm="12">
					<div className="card-header d-flex pb-2 justify-content-end">
						<h5 className="">Motoristas</h5>
						<span
							className="filter-data"
							onClick={() =>
								setDataParamMotoristas((last) => ({ ...last, show: true }))
							}
						>
							Filtrar Data
							<span className="filho">{dataMotoristas}</span>
							<i
								className="bi bi-calendar-date"
								style={{ marginLeft: "5px" }}
							></i>
						</span>
						<span
							className="dashboard-filters"
							onClick={() => {
								setShowFilters((last) => ({
									...last,
									show: true,
									arrayData: arrayDataMotoristas,
									setFilteredTable: setDataMotoristasFiltered,
									variable: "motoristas",
									filterOptions: ["id_filial", "tipo_carga", "regiao_entrega"],
								}));
							}}
						>
							Filtros
							<i className="bi bi-gear" style={{ marginLeft: "5px" }}></i>
						</span>
					</div>
				</Col>
			</Row>
			<Row>
				<Col sm="12" md="6" xl="4">
					<GraficoTotalEntregueMotorista
						url="dashboard/empresa/get-totais-motoristas"
						title="Cargas Entregue por Motorista"
						unidadeMedida=""
						dataParam={dataParamMotoristas}
						colors="#6E585E"
						arrayData={arrayDataMotoristas}
						arrayDataFiltered={dataMotoristasFiltered}
						// setArraydata={setArrayDataMotoristas}
					/>
				</Col>
				<Col sm="12" md="6" xl="4">
					<GraficoPadraoComDataHorizontalMotoristas
						url="dashboard/empresa/get-totais-motoristas"
						title="Volume Carregado por Motorista"
						unidadeMedida="m3"
						posisaoUnidadeMedida={"atras"}
						parametroGrafico={"volume_total"}
						dataParam={dataParam}
						colors="#EDE577"
						arrayData={arrayDataMotoristas}
						arrayDataFiltered={dataMotoristasFiltered}
						setArraydata={setArrayDataMotoristas}
					/>
				</Col>
				<Col sm="12" md="6" xl="4">
					<GraficoPadraoComDataHorizontalMotoristas
						url="dashboard/empresa/get-totais-motoristas"
						title="Total Peso Carregado por Motorista"
						unidadeMedida="kg"
						posisaoUnidadeMedida={"atras"}
						parametroGrafico={"peso_total"}
						dataParam={dataParam}
						colors="#77D0ED"
						arrayData={arrayDataMotoristas}
						arrayDataFiltered={dataMotoristasFiltered}
						setArraydata={setArrayDataMotoristas}
					/>
				</Col>
				<Col sm="12" md="6" xl="4">
					<GraficoPadraoComDataHorizontalMotoristas
						url="dashboard/empresa/get-totais-motoristas"
						title="Total m2 Carregado por Motorista"
						unidadeMedida="m2"
						posisaoUnidadeMedida={"atras"}
						parametroGrafico={"metros_quadrados"}
						dataParam={dataParam}
						colors="#6E585E"
						arrayData={arrayDataMotoristas}
						arrayDataFiltered={dataMotoristasFiltered}
						setArraydata={setArrayDataMotoristas}
					/>
				</Col>
			</Row>

			<Row className="mt-4">
				<Col sm="12">
					<div className="card-header d-flex pb-2 justify-content-end">
						<h5 className="">Timeline das Cargas</h5>
						<span
							className="filter-data"
							onClick={() =>
								setDataParamCargas((last) => ({ ...last, show: true }))
							}
						>
							Filtrar Data
							<span className="filho">{dataParamCargas.title}</span>
							<i
								className="bi bi-calendar-date"
								style={{ marginLeft: "5px" }}
							></i>
						</span>
					</div>
				</Col>
			</Row>
			<Row>
				<Col sm="12" md="12" >
					<GraficoDateLead
						title="Tempo da Logística"
						arrayData={dadosCargas}
						dataInicial={ dataParamCargas.data_inicial }
					/>
				</Col>
			</Row>

			{dataParam.show ? (
				<ModalAtualizarDataEntradaVST
					title={"Perido do Gráfico"}
					show={dataParam.show}
					setShow={setDataParam}
					setDataParam={setDataParam}
					fn={atualizarDataParametro}
					{...dataParam}
				/>
			) : (
				""
			)}
			{dataParamMotoristas.show ? (
				<ModalAtualizarDataEntradaVST
					title={"Perido do Gráfico dos Motoristas"}
					show={dataParamMotoristas.show}
					setShow={setDataParamMotoristas}
					{...dataParamMotoristas}
					setDataParam={setDataParamMotoristas}
					fn={atualizarDataParametroMotorista}
				/>
			) : (
				""
			)}
			{dataParamCaminhoes.show ? (
				<ModalAtualizarDataEntradaVST
					title={"Perido do Gráfico dos Motoristas"}
					show={dataParamCaminhoes.show}
					setShow={setDataParamCaminhoes}
					{...dataParamCaminhoes}
					setDataParam={setDataParamCaminhoes}
					fn={atualizarDataParametroCaminhoes}
				/>
			) : (
				""
			)}
			{dataParamCaminhoes2.show ? (
				<ModalAtualizarDataEntradaVST
					title={"Perido do Gráfico dos Motoristas"}
					show={dataParamCaminhoes2.show}
					setShow={setDataParamCaminhoes2}
					{...dataParamCaminhoes2}
					setDataParam={setDataParamCaminhoes2}
					fn={atualizarDataParametroCaminhoes2}
				/>
			) : (
				""
			)}
			{dataParamCaminhoes3.show ? (
				<ModalAtualizarDataEntradaVST
					show={dataParamCaminhoes3.show}
					setShow={setDataParamCaminhoes3}
					{...dataParamCaminhoes3}
					setDataParam={setDataParamCaminhoes3}
					fn={atualizarDataParametroCaminhoes3}
				/>
			) : (
				""
			)}
			{dataParamCaminhoes4.show ? (
				<ModalAtualizarDataEntradaVST
					show={dataParamCaminhoes4.show}
					setShow={setDataParamCaminhoes4}
					{...dataParamCaminhoes4}
					setDataParam={setDataParamCaminhoes4}
					fn={atualizarDataParametroCaminhoes4}
				/>
			) : (
				""
			)}
			{showFilters.show ? (
				<ModalFiltros
					{...showFilters}
					setShow={setShowFilters}
					arrayFilters={arrayFiltersN}
					setArrayFiltes={setArrayFiltesN}
				/>
			) : (
				""
			)}
			{dataParamCargas.show ? (
				<ModalAtualizarDataEntradaVST
					show={dataParamCargas.show}
					setShow={setDataParamCargas}
					{...dataParamCargas}
					setDataParam={setDataParamCargas}
					fn={(i, f) => {
						setDataParamCargas(last => ({ ...last, data_inicial: i, data_final: f }))
						getCargas(i, f)
					}}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}

export function ApexChartColumns(props) {
	return (
		<div>
			<Chart
				options={props.options}
				series={props.series}
				type="bar"
				height={300}
			/>
		</div>
	);
}
function ApexChartPie(props) {
	return (
		<div>
			<Chart
				options={props.options}
				series={props.series}
				height={500}
				type="donut"
			/>
		</div>
	);
}
