import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
	alterData,
	cpf_mask,
	getDateInitMonth,
	insertInto,
	updateTableNew,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import { getErrorMessage, telefoneMask } from "../../services/helpers";
import ModalError from "../modal/ModalError";
import ModalErrorPretty from "../modal/ModalErrorPretty";

const motivosAberturaChamado = [
	{ label: 'Problemas com as Cargas'			, prioridade: '' },
	{ label: 'Problemas com os Pedidos'			, prioridade: '' },
	{ label: 'Problemas com os Produtos'		, prioridade: '' },
	{ label: 'Problemas com as Embalagens'		, prioridade: '' },
	{ label: 'Problemas com as Transferências'	, prioridade: '' },
	{ label: 'Problemas com a Frota'			, prioridade: '' },
	{ label: 'Problemas com o Abastecimento'	, prioridade: '' },
	{ label: 'Problemas com a Manutenção'		, prioridade: '' },
	{ label: 'Finalizar Carga Manualmente'		, prioridade: '' },
	{ label: 'Excluir Pedido Entregue'			, prioridade: '' },
	{ label: 'Informar QRCode'					, prioridade: '' },
]

const ambienteUsuarios = [
	{ label: 'Gestão' },
	{ label: 'Separação' },
	{ label: 'Abastecimento' },
	{ label: 'Frota' },
	{ label: 'Manutenção' },
	{ label: 'Financeiro' },
	{ label: 'Transferencias' },
	{ label: 'Motorista' },
]

const possiveisStatus = [
	{ label: 'Aberto', default: true },
	{ label: 'Re-Aberto' },
	{ label: 'Encerrado' },
]

export default function ModalLancamentoChamados(props) {
	const user = useSelector((state) => state.userSlice);
	const filiaisList = user.filiaisList.data

	const [loading, setLoading] = useState({
		isLoading: false,
		msg: props.chamado ? "Atualizar" : "Salvar",
		style:  props.chamado ? "dark" : "primary",
	});

	const [body, setBody] = useState({ ...props.chamado });
	const [error, setError] = useState({
		flag: false,
	});

	const handlerSimpleClose = () => props.setShow((last) => ({ ...last, show: false }));

	const submitDataUpdate = async () => {
		if (props.chamado.status == 'ENCERRADO' && body.status != 'RE-ABERTO') {
			setError({
				show: true,
				message: 'Não é possivel atualizar um chamado Encerrado',
			});
			return;
		}
		if (!body.solicitante) {
			setError({
				show: true,
				message: 'Solicitante não informado',
			});
			return;
		}
		if (!body.filial) {
			setError({
				show: true,
				message: 'Filial não informada',
			});
			return;
		}
		if (!body.ambiente) {
			setError({
				show: true,
				message: 'Ambiente não informado',
			});
			return;
		}

		setLoading({
			isLoading: true,
			msg: "Atualizando",
			style: "warning",
		});



		const url = 'controle-de-chamados'
		let bodyL = {...body}
		if(body.status === 'ENCERRADO'){
			bodyL.data_encerramento = new Date()
		}

		try {
			const resp = await updateTableNew(url, {
				emissao: new Date(),
				body: bodyL
			})
				
			setLoading({
				isLoading: false,
				msg: "Atualizado",
				style: "success",
			});
			setTimeout(() =>{
				
				const date = new Date();
				const dateCorrect = getDateInitMonth(date);
				props.fnRetorno(dateCorrect.data_inicial, dateCorrect.data_final)
				handlerSimpleClose()

			}, 1000)
		} catch (error) {
			setError({
				show: true, message: getErrorMessage(error)
			})
			setLoading({
				isLoading: false,
				msg: "Atualizar",
				style: "dark",
			});
		} 
	};
	
	const submitDataEdit = async () => {
		if (!body.solicitante) {
			setError({
				show: true,
				message: 'Solicitante não informado',
			});
			return;
		}
		if (!body.filial) {
			setError({
				show: true,
				message: 'Filial não informada',
			});
			return;
		}
		if (!body.ambiente) {
			setError({
				show: true,
				message: 'Ambiente não informado',
			});
			return;
		}

		setLoading({
			isLoading: true,
			msg: "Salvando",
			style: "warning",
		});



		const url = 'controle-de-chamados'

		try {
			const resp = await insertInto(url, {
				emissao: new Date(),
				...body
			})
				
			setLoading({
				isLoading: false,
				msg: "Salvo",
				style: "success",
			});
			setTimeout(() =>{
				
				const date = new Date();
				const dateCorrect = getDateInitMonth(date);
				props.fnRetorno(dateCorrect.data_inicial, dateCorrect.data_final)
				handlerSimpleClose()

			}, 1000)
		} catch (error) {
			setError({
				show: true, message: getErrorMessage(error)
			})
			setLoading({
				isLoading: false,
				msg: "Salvar",
				style: "primary",
			});
		} 
	};

	const onChangeInput = (e) => {

		if (e.target.name === "cpf") {
			setBody((last) => ({
				...last,
				[e.target.name]: cpf_mask(e.target.value),
			}));
			return;
		}
		if (e.target.name === "telefone") {
			setBody((last) => ({
				...last,
				[e.target.name]: telefoneMask(e.target.value),
			}));
			return;
		}
		setBody((last) => ({
			...last,
			[e.target.name]: e.target.value.toUpperCase(),
		}));
	};


	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>Novo Chamado</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form
						onSubmit={(e) => {
							e.preventDefault();
							submitDataEdit();
						}}
					>

						<Row className="mb-2">
							<Col sm="4">
								<b><Form.Label>Status:</Form.Label></b>
							</Col>
							<Col>
								<Form.Select onChange={onChangeInput} name='status' defaultValue={body.status}>
									<option></option>
									{
										possiveisStatus
											.map((item, index) => <option
												key={index}
												value={item.label.toUpperCase()}
											>{item.label} </option>
											)
									}
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<b><Form.Label>Solicitante:</Form.Label></b>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="solicitante"
									onChange={onChangeInput}
									value={body.solicitante}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<b><Form.Label>Filial:</Form.Label></b>
							</Col>
							<Col>
								<Form.Select onChange={onChangeInput} name='filial' defaultValue={body.filial}>
									<option></option>
									{filiaisList.map((item, index) => <option key={index} value={item.idFilial}>{item.identificacaoInterna}</option>)}
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<b><Form.Label>Ambiente:</Form.Label></b>
							</Col>
							<Col>
								<Form.Select onChange={onChangeInput} name='ambiente' defaultValue={body.ambiente}>
									<option></option>
									{
										ambienteUsuarios
											.map((item, index) => <option key={index} value={item.label.toUpperCase()}>{item.label} </option>)
									}
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<b><Form.Label>Motivo:</Form.Label></b>
							</Col>
							<Col>
								<Form.Select onChange={onChangeInput} name='motivo' defaultValue={body.motivo}>
									<option></option>
									{
										motivosAberturaChamado
											.map((item, index) => <option key={index} value={item.label.toUpperCase()}>{item.label} </option>)
									}
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<b>
									<Form.Label className="text-bolder">Relato:</Form.Label>
								</b>
							</Col>
							<Col>
								<Form.Text
									as='textarea'
									name="relato"
									className="form-control"
									rows={3}
									style={{ width: '100%' }}
									onChange={onChangeInput}
									value={body.relato}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<b>
									<Form.Label className="text-bolder">Solução:</Form.Label>
								</b>
							</Col>
							<Col>
								<Form.Text
									as='textarea'
									name="solucao"
									className="form-control"
									rows={3}
									style={{ width: '100%' }}
									onChange={onChangeInput}
									value={body.solucao}
								/>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						{!props.chamado &&
						<Col>
							<Button
								type="submit"
								variant={loading.style}
								onClick={submitDataEdit}
								disabled={loading.style == "primary" ? false : true}
							>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
								{loading.style == "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</Button>
						</Col>
						}
						{
							props.chamado &&
							<Col>
								<Button
									type="submit"
									variant={loading.style}
									onClick={submitDataUpdate}
									disabled={loading.style == "dark" ? false : true}
								>
									{loading.isLoading ? (
										<Loading msg={loading.msg} />
									) : (
										loading.msg
									)}
									{loading.style == "success" ? (
										<i className="bi bi-check"></i>
									) : (
										""
									)}
								</Button>
							</Col>
						}
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
				{error.show ? (
					<ModalErrorPretty
						setShow={setError}
						{...error}
					/>
				) : (
					""
				)}
			</Modal>
		</>
	);
}

