import { CreateUser, Login } from "./loginlogout/LogInOut";
import { Routes, Route, Navigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import NotFound404 from "./notFound/NotFound404";
import {
	PageAbastecimento,
	PageCarregamento,
	PageChat,
	PageControleDePonto,
	PageDashboard,
	PageDescarregamento,
	PageDiario,
	PageEquipamentos,
	PageFinanceiro,
	PageFrota,
	PageGestaoCarregamento,
	PageInbox,
	PageManutencao,
	PageMap,
	PageMensagens,
	PageMonitPedidos,
	PageMonitPedidosFinanceiro,
	PageMonitoramentoFrota,
	PageMotoristas,
	PagePlanoLogistica,
	PageSeguranca,
	PageSeparacaoCarga,
	PageSuporte,
	PageTransferencias,
	UserRoute,
} from "./Pages";
import { connect, useDispatch, useSelector } from "react-redux";

import UserProfile from "./user/perfil/UserProfile.js";
import UserSettings from "./user/configurações/UserSettings";
import UserCadastro from "./user/cadastro/UserCadastro";
import UserCadastrado from "./user/cadastrado/UserCadastrado";
import ProtectedRoute from "./protectedRoute/protectedRoute";

import socketClient from "socket.io-client";
import { locationDev, setOnline, updateTable } from "../services/Servicos";
import {
	update,
	updateLocationsList,
	updateNotificacoesList,
	updateProdutosEEmbalagensBySku,
} from "../redux/slicers/user";
import CaminhaoCadastrado from "./frota/cadastrado/CaminhaoCadastrado";
import CaminhaoCadastro from "./frota/cadastro/CaminhaoCadastro";
import { ForgotPassword } from "./forgotPassword/ForgotPassword";
import { RecoverPassword } from "./recoverPassword/RecoverPassword";
import Rotas from "./planoLogistica/rotas/Rotas";
import Romaneios from "./planoLogistica/romaneios/Romaneios";
import CaminhaoCaminhoneiro from "./frota/caminhao-caminhoneiro/CaminhaoCaminhoneiro";
import ConsultarPontos from "./controle-ponto/ConsultarPontos";
import { CadastrarPonto } from "./controle-ponto/CadastrarPonto";
import Cargas from "./planoLogistica/cargas/Cargas";
import { SeparacaoDeCargas } from "./separacao-carga/cargas/Separar-Pedidos";
import { Conferencias } from "./separacao-carga/cargas/Conferencias";
import Pedidos from "./planoLogistica/apiVST/Pedidos";
import { ConfirmacaoGestor } from "./financeiro/ConfirmacaoGestor";
import { ConfirmacaoContabilidade } from "./financeiro/ConfirmacaoContabilidade";
import CargasEntregues from "./planoLogistica/cargas/CargasEntregues";
import ConsultarDiario from "./diarioDeBordo/ConsultarDiario";
import ConsultarDiarioGestor from "./diarioDeBordo/ConsultarDiarioGestor";
import { ConfirmacaoAdicionalCredito } from "./financeiro/ConfirmacaoAdicionalCredito";
import PedidosJaIncluidos from "./planoLogistica/apiVST/PedidosJaIncluidos";
import { ConfirmacaoFechamento } from "./financeiro/ConfirmacaoFechamento";
import Embalagens from "./planoLogistica/embalagens/Embalagens";
import PedidosTotal from "./planoLogistica/apiVST/PedidosTotal";
import EquipamentosCadastrados from "./equipamentos/cadastrado/EquipamentosCadastrados";
import EquipamentoCadastro from "./equipamentos/cadastro/EquipamentoCadastro";
import TransferenciasEmitidas from "./transferencias/cadastrado/TransferenciasEmitidas";
import TransferenciasCadastro from "./transferencias/cadastro/TransferenciasCadastro";
import CaminhaoCadastroFreteiro from "./frota/cadastro-freteiro/CaminhaoCadastroFreteiro";
import TransferenciasFinalizadas from "./transferencias/finalizados/TransferenciasFinalizadas.js";
import RelatorioPontos from "./controle-ponto/RelatorioPontos.js";
import RelatorioPontosGerentes from "./controle-ponto/RelatorioPontosGerentes.js";
import Manifestos from "./planoLogistica/manifestos/Manifestos.js";
import Manutencao from "./frota/manutencao/Manutencao.js";
import RelatorioTransferencias from "./transferencias/relatorio/RelatorioTransferencias.js";
import ControleDeMensagens from "./controleDeMensagens/ControleDeMensagens.js";
import MensagensClientes from "./controleDeMensagens/MensagensClientes.js";
import ControleChamados from "./controleDeMensagens/ControleChamados.js";
const socket = socketClient(locationDev, { autoConnect: false });

function App() {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.userSlice);
	const danfes = useSelector((state) => state.cargaSlicer.listasDanfes);
	const produtosEEmbalagens = user.produtosEEmbalagens?.data;
	const cargas = user.cargaSelecionada;

	const [conectado, setConectado] = useState(socket.connected);
	const locations = useSelector((state) => state.userSlice.locationsList);
	const sucessCallbackLocation = (position) => {
		socket.emit(
			"location",
			{
				truckInfo: {
					position: {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					},
					condutor_nome: user.name,
					condutor: user.cpf,
					cargas_id: user.cargaSelecionada[0]?.id,
					caminhao: user.caminhaoSelecionado.caminhao_placa,
				},
				carga_info: {
					romaneios: user.romaneiosList.data.map((item) => ({
						romaneio: item.romaneio,
						numero_pedido: item.numero_pedido,
						status: item.status,
						valor: item.valor || item.valor_total,
					})),
					produtos: user.produtosList.data.map((item) => ({
						numero_pedido: item.numero_pedido,
						codigo: item.codigo,
						descricao: item.descricao,
						embalado: item.embalado,
						descarregado: item.descarregado,
						numero_embalagem: item.numero_embalagem,
						descarregado: item.descarregado,
						peso: item.peso,
						volume: item.volume,
						qtde: item.qtde,
					})),
					embalagens: user.embalagensList.data.map((item) => ({
						numero_embalagem: item.numero_embalagem,
						descricao: item.descricao,
						descarregado: item.descarregado,
						peso: item.peso,
						volume: item.volume,
					})),
				},
				msg_info: {
					data: new Date().toISOString(),
				},
			},
			(error) => {}
		);
	};

	const errorCallbackLocation = (position) => {};
	const atualizarProdutos = async () => {
		dispatch(update({ integracao: true }));
		try {
			const produtosDescarregado = produtosEEmbalagens.filter(
				(item) => item.descarregado && !item.integrado
			);
			// const produtosDescarregado = produtosEEmbalagens.filter(item => item.descarregado)

			if (produtosDescarregado.length) {
				for await (let produto of produtosDescarregado) {
					const objAtt = {
						user_descarregamento: produto.user_descarregamento,
						data_descarregamento: produto.data_descarregamento,
						descarregado: true,
						qr_sku: produto.qr_sku,
						tipo: produto.tipo,
						regiao_entrega: cargas.find((item) => item.id === produto.cargas_id)
							?.regiao_entrega,
						numero_embalagem: produto.numero_embalagem,
					};
					try {
						const updated = await updateTable(
							"cargas/motorista/update-descarregamento",
							produto.cargas_id,
							{ ...objAtt }
						);
						dispatch(
							updateProdutosEEmbalagensBySku({
								parameter: "integrado",
								qr_sku: produto.qr_sku,
								integrado: true,
							})
						);
					} catch (error) {
						dispatch(update({ integracao: false }));
						break;
					}
				}
			}
		} catch (error) {}
		setTimeout(() => {
			dispatch(update({ integracao: false }));
		}, 500);
	};

	useEffect(() => {
		if (!user.authenticated || !user.tipo === "Caminhoneiro") return;

		if (!user?.processo_entrega?.flag) return;

		const interval = setInterval(
			() => {
				navigator.geolocation.getCurrentPosition(
					sucessCallbackLocation,
					errorCallbackLocation
				);
			},
			1000 * 60,
			user
		);

		setTimeout(() => {
			console.log('shakehand')
			navigator.geolocation.getCurrentPosition(
				sucessCallbackLocation,
				errorCallbackLocation
			);
		}, 0)
		return () => clearInterval(interval);
	}, [
		user.cargas_id,
		user.processo_entrega,
		user.produtosList,
		user.embalagensList,
		user.romaneiosList,
	]);

	// useEffect(() => {
	//     if ((!user.authenticated || !user.tipo === 'Caminhoneiro')) return

	//     if (!user?.processo_entrega?.flag) return

	//     const interval = setInterval(() => {
	//         atualizarProdutos()
	//     }, 5000, user);

	//     return () => clearInterval(interval);
	// }, [user.processo_entrega, produtosEEmbalagens.filter(item => item.descarregado)]);

	useEffect(() => {
		/* remover usuário da tabela online no fechamento da pagina */
		window.onbeforeunload = () => {
			dispatch(update({ online: false }));
			// clearPersistData()
		};

		socket.on("connect", () => {
			//
			if (!user.email && !user.cpf && !conectado) return;
			setOnline(user, socket);
			setConectado(true);
		});

		socket.on("notification", (msgs) => {
			dispatch(updateNotificacoesList(msgs.notificacoes));
		});

		socket.on("locations_truck", (msgs) => {
			if (user.tipo.toLowerCase() !== "empresa") return;
			if (!msgs?.msg?.truckInfo?.condutor) return;
			const condutor = msgs.msg.truckInfo.condutor;
			// //.filter(item => item.truckInfo.condutor !== condutor ))
			const locationsUp = [
				...locations.data.filter(
					(item) => item.truckInfo.condutor !== condutor
				),
				msgs.msg,
			];
			dispatch(updateLocationsList(locationsUp));
		});

		return () => {
			socket.off("connect");
			socket.off("disconnect");
			socket.off("notification");
			socket.off("locations_truck");
			// localStorage.clear()
		};
	}, [user]);

	useEffect(() => {
		if (user.authenticated /* && user.tipo !== 'empresa' */ && !conectado) {
			socket.connect();
		}
	}, [user, conectado]);

	return (
		<Fragment>
			<Routes>
				<Route
					path="/"
					element={
						<ProtectedRoute user={user.authenticated}>
							<Navigate to={"/dashboard"} />
							{/*  <PageDashboard /> */}
						</ProtectedRoute>
					}
				/>
				<Route
					path="dashboard"
					element={
						<ProtectedRoute user={user.authenticated}>
							<PageDashboard />
						</ProtectedRoute>
					}
				/>
				<Route
					path="monitoramento-pedidos"
					element={
						<ProtectedRoute user={user.authenticated}>
							{verificarTipoGestao(user.tipo, <PageMonitPedidos />, "/login")}
						</ProtectedRoute>
					}
				/>
				<Route
					path="monitoramento-pedido-financeiro"
					element={
						<ProtectedRoute user={user.authenticated}>
							{verificarTipoFinanceiro(user.tipo, <PageMonitPedidosFinanceiro />, "/login")}
						</ProtectedRoute>
					}
				/>
				<Route
					path="motoristas"
					element={
						<ProtectedRoute user={user.authenticated}>
							{verificarTipoGestao(user.tipo, <PageMotoristas />, "/login")}
						</ProtectedRoute>
					}
				/>
				{/* <Route
                    path="inbox"
                    element={
                        <ProtectedRoute user={user.authenticated}>
                            <PageInbox />
                        </ProtectedRoute>
                    }
                /> */}
				<Route
					path="map"
					element={
						<ProtectedRoute user={user.authenticated}>
							{verificarTipos(user.tipo, <PageMap />, "/login")}
						</ProtectedRoute>
					}
				/>
				<Route
					path="descarregamento"
					element={
						<ProtectedRoute user={user.authenticated}>
							{verificarTipos(user.tipo, <PageDescarregamento />, "/login")}
						</ProtectedRoute>
					}
				/>
				<Route
					path="manutencao"
					element={
						<ProtectedRoute user={user.authenticated}>
							{verificarTipos(user.tipo, <PageManutencao />, "/login")}
						</ProtectedRoute>
					}
				/>

				<Route
					path="diario"
					element={
						<ProtectedRoute user={user.authenticated}>
							{verificarTipos(user.tipo, <PageDiario />, "/login")}
						</ProtectedRoute>
					}
				>
					<Route path="" index element={<Navigate to="lancamentos" />} />
					<Route
						path="lancamentos"
						element={verificarTipoCaminhoneiro(
							user.tipo,
							<ConsultarDiario />,
							"/diario/gestao"
						)}
					/>
					<Route
						path="gestao"
						element={verificarTipos(
							user.tipo,
							<ConsultarDiarioGestor />,
							"/login"
						)}
					/>
				</Route>

				<Route
					path="transferencias"
					element={
						<ProtectedRoute user={user.authenticated}>
							{verificarTipoGestao(user.tipo, <PageTransferencias />, "/login")}
						</ProtectedRoute>
					}
				>
					<Route path="" index element={<Navigate to="lancamentos" />}></Route>
					<Route
						path="finalizados"
						element={verificarTipoGestao(
							user.tipo,
							<TransferenciasFinalizadas />,
							"/login"
						)}
					/>
					<Route
						path="lancamentos"
						element={verificarTipoGestao(
							user.tipo,
							<TransferenciasEmitidas />,
							"/login"
						)}
					/>
					<Route
						path="emissao"
						element={verificarTipoGestao(
							user.tipo,
							<TransferenciasCadastro />,
							"/login"
						)}
					/>
					<Route
						path="relatorio"
						element={verificarTipoGestao(
							user.tipo,
							<RelatorioTransferencias />,
							"/login"
						)}
					/>
				</Route>
				<Route
					path="equipamentos"
					element={
						<ProtectedRoute user={user.authenticated}>
							{verificarTipo(user.tipo, <PageEquipamentos />, "/login")}
						</ProtectedRoute>
					}
				>
					<Route path="" index element={<Navigate to="cadastrados" />}></Route>
					<Route
						path="cadastrados"
						element={verificarTipo(
							user.tipo,
							<EquipamentosCadastrados />,
							"/login"
						)}
					/>
					<Route
						path="cadastro"
						element={verificarTipo(
							user.tipo,
							<EquipamentoCadastro />,
							"/login"
						)}
					/>
				</Route>

				<Route
					path="plano-logistica"
					element={
						<ProtectedRoute user={user.authenticated}>
							{verificarTipoGestao(user.tipo, <PagePlanoLogistica />, "/login")}
						</ProtectedRoute>
					}
				>
					<Route path="" index element={<Navigate to="cargas" />} />
					<Route
						path="vendas"
						element={verificarTipoGestao(user.tipo, <Pedidos />, "/login")}
					/>
					<Route
						path="pedidos_total"
						element={verificarTipoGestao(user.tipo, <PedidosTotal />, "/login")}
					/>
					<Route
						path="pedidos/cargas"
						element={verificarTipoGestao(
							user.tipo,
							<PedidosJaIncluidos />,
							"/login"
						)}
					/>
					<Route
						path="rotas"
						element={verificarTipoGestao(user.tipo, <Rotas />, "/login")}
					/>
					<Route
						path="cargas"
						element={verificarTipoGestao(user.tipo, <Cargas />, "/login")}
					/>
					<Route
						path="embalagens"
						element={verificarTipoGestao(user.tipo, <Embalagens />, "/login")}
					/>
					<Route
						path="entregues"
						element={verificarTipoGestao(
							user.tipo,
							<CargasEntregues />,
							"/login"
						)}
					/>
					<Route
						path="manifestos"
						element={verificarTipoGestao(user.tipo, <Manifestos />, "/login")}
					/>
					<Route
						path="manifestos"
						element={verificarTipoGestao(user.tipo, <Manifestos />, "/login")}
					/>
					<Route
						path="romaneios"
						element={verificarTipoGestao(user.tipo, <Romaneios />, "/login")}
					/>
					{/* <Route path='cadastro' element={verificarTipo(user.tipo, <CaminhaoCadastro />, '/login')} /> */}
				</Route>
				<Route
					path="frota"
					element={
						<ProtectedRoute user={user.authenticated}>
							{verificarTipoGestao(user.tipo, <PageFrota />, "/login")}
						</ProtectedRoute>
					}
				>
					<Route path="" index element={<Navigate to="cadastrados" />} />
					<Route
						path="cadastrados"
						element={verificarTipoGestao(
							user.tipo,
							<CaminhaoCadastrado />,
							"/login"
						)}
					/>
					<Route
						path="cadastro"
						element={verificarTipoGestao(
							user.tipo,
							<CaminhaoCadastro />,
							"/login"
						)}
					/>
					<Route
						path="cadastro-freteiro"
						element={verificarTipoGestao(
							user.tipo,
							<CaminhaoCadastroFreteiro />,
							"/login"
						)}
					/>
					<Route
						path="caminhao-caminhoneiro"
						element={verificarTipoGestao(
							user.tipo,
							<CaminhaoCaminhoneiro />,
							"/login"
						)}
					/>
					<Route
						path="manutencoes"
						element={verificarTipoGestao(user.tipo, <Manutencao />, "/login")}
					/>
				</Route>

				<Route
					path="carregamento"
					element={
						<ProtectedRoute user={true}>
							{verificarTipoCaminhoneiro(
								user.tipo,
								<PageCarregamento />,
								"/login"
							)}
						</ProtectedRoute>
					}
				/>
				<Route
					path="carregamento-gestor"
					element={
						<ProtectedRoute user={true}>
							{verificarTipoGestao(
								user.tipo,
								<PageGestaoCarregamento />,
								"/login"
							)}
						</ProtectedRoute>
					}
				/>

				<Route
					path="financeiro"
					element={
						<ProtectedRoute user={user.authenticated}>
							{verificarTipoGestao(user.tipo, <PageFinanceiro />, "/login")}
						</ProtectedRoute>
					}
				/>
				<Route path="/financeiro-confirmacao" element={<ConfirmacaoGestor />} />
				<Route
					path="/financeiro-confirmacao-contabilidade"
					element={<ConfirmacaoContabilidade />}
				/>
				<Route
					path="/financeiro-adicional-credito"
					element={<ConfirmacaoAdicionalCredito />}
				/>
				<Route
					path="/financeiro-confirmacao-fechamento"
					element={<ConfirmacaoFechamento />}
				/>

				<Route
					path="abastecimento"
					element={
						<ProtectedRoute user={user.authenticated}>
							{verificarTipoGestao(user.tipo, <PageAbastecimento />, "/login")}
						</ProtectedRoute>
					}
				/>
				<Route
					path="separacao"
					element={
						<ProtectedRoute user={user.authenticated}>
							<PageSeparacaoCarga />
						</ProtectedRoute>
					}
				>
					<Route path="" element={<Navigate to={"cargas"} />} />
					<Route
						path="cargas"
						element={verificarTipoSeparador(
							user.tipo,
							<SeparacaoDeCargas />,
							"/login"
						)}
					/>
					<Route
						path="separar"
						element={verificarTipoSeparador(
							user.tipo,
							<Conferencias />,
							"/login"
						)}
					/>
					{/* <Route path="cadastrar" element={verificarTipoFuncionarios(user.tipo, <CadastrarPonto />, '/controle-ponto/consultar')} /> */}
				</Route>
				<Route
					path="seguranca"
					element={
						<ProtectedRoute user={user.authenticated}>
							{verificarTipo(user.tipo, <PageSeguranca />, "/login")}
						</ProtectedRoute>
					}
				/>
				<Route
					path="monitoramento-frota"
					element={
						<ProtectedRoute user={user.authenticated}>
							{verificarTipoGestao(user.tipo, <PageMonitoramentoFrota />, "/login")}
						</ProtectedRoute>
					}
				/>
				<Route
					path="controle-ponto"
					element={
						<ProtectedRoute user={user.authenticated}>
							<PageControleDePonto />
						</ProtectedRoute>
					}
				>
					<Route path="" element={<Navigate to="consultar" />} />
					<Route
						path="consultar"
						element={verificarTipo(
							user.tipo,
							<ConsultarPontos />,
							"/controle-ponto/cadastrar"
						)}
					/>
					<Route
						path="cadastrar"
						element={verificarTipoFuncionarios(
							user.tipo,
							<CadastrarPonto />,
							"/controle-ponto/relatorio"
						)}
					/>
					<Route
						path="relatorio"
						element={verificarTipoFuncionarios(
							user.tipo,
							<RelatorioPontos />,
							"/dashboard"
						)}
					/>
					<Route
						path="relatorio-admin"
						element={verificarTipoFuncionarios(
							user.tipo,
							<RelatorioPontosGerentes />,
							"/dashboard"
						)}
					/>
				</Route>
				<Route path="create-user" element={<CreateUser />} />
				<Route path="login" element={<Login socket={socket} />} />
				<Route path="recuperar-senha" element={<ForgotPassword />} />
				<Route path="nova-senha" element={<RecoverPassword />} />
				<Route
					path="/user"
					element={
						<ProtectedRoute user={user.authenticated}>
							<UserRoute />
						</ProtectedRoute>
					}
				>
					<Route path="" element={<Navigate to="cadastrados" />} />
					<Route path="profile" element={<UserProfile />} />
					<Route path="settings" element={<UserSettings />} />
					<Route
						path="cadastro"
						element={verificarTipoSuporteEmpresa(
							user.tipo,
							<UserCadastro />,
							"/user/profile",
							user.email
						)}
					/>
					<Route
						path="cadastrados"
						element={verificarTipoSuporteEmpresa(
							user.tipo,
							<UserCadastrado />,
							"/user/profile",
							user.email
						)}
					/>
				</Route>

				<Route
					path="suporte"
					element={
						<ProtectedRoute user={user.authenticated}>
							{verificarTipoSuporte(user.email, <PageSuporte />, "/login")}
						</ProtectedRoute>
					}
				>
					<Route path="" element={<Navigate to="controle-mensagens" />} />
					<Route path="controle-mensagens" element={<ControleDeMensagens />} />
					<Route path="mensagens-clientes" element={<MensagensClientes />} />
					<Route path="chamados" element={<ControleChamados />} />

				</Route>

				<Route path="*" element={<NotFound404 />} />
			</Routes>
		</Fragment>
	);
}

function verificarTipoSeparador(tipo, children, route) {
	return tipo.toLowerCase() === "separador" ? (
		children
	) : (
		<Navigate to={route} />
	);
}
function verificarTipo(tipo, children, route) {
	return tipo === "empresa" ? children : <Navigate to={route} />;
}

function verificarTipoCaminhoneiro(tipo, children, route) {
	return tipo === "Caminhoneiro" ? children : <Navigate to={route} />;
}
function verificarTipoFuncionarios(tipo, children, route) {
	return tipo !== "Empresa" ? children : <Navigate to={route} />;
}

function verificarTipos(tipo, children, route) {
	return tipo === "Caminhoneiro" || tipo.toLowerCase() === "empresa" ? (
		children
	) : (
		<Navigate to={route} />
	);
}
function verificarTipoGestao(tipo, children, route) {
	return tipo.toLowerCase() === "gestor" || tipo.toLowerCase() === "empresa" ? (
		children
	) : (
		<Navigate to={route} />
	);
}
function verificarTipoFinanceiro(tipo, children, route) {
	return tipo.toLowerCase() === "financeiro" ? (
		children
	) : (
		<Navigate to={route} />
	);
}
function verificarTipoSuporte(tipo, children, route) {
	return tipo.toUpperCase() === process.env.REACT_APP_EMAIL_SUPORTE ? (
		children
	) : (
		<Navigate to={route} />
	);
}

function verificarTipoSuporteEmpresa(tipo, children, route, email) {
	return email?.toUpperCase() === process.env.REACT_APP_EMAIL_SUPORTE ||
		tipo?.toLowerCase() === "empresa" ? (
		children
	) : (
		<Navigate to={route} />
	);
}

export default App;
