import { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { QRCodeSVG } from "qrcode.react";

import "./Modal.scss";
import {
	apenasNumeros,
	getCorretData,
	getStatusPedidos,
	numeroDecimais,
	numerosDecimal,
	numerosEditadoBR,
	valorMoeda,
} from "../../services/Servicos";
import { telefoneMask } from "../../services/helpers";

export default function ModalEditarRowProduto(props) {
	const user = useSelector((state) => state.userSlice);
	const handlerSimpleClose = () => props.setShow((last) => ({ show: false }));

	const [body, setBody] = useState(props.row);
	const [btn, setBtn] = useState({
		flag: false,
		style: "primary",
		msg: "Salvar",
	});
	const [bodyEdit, setBodyEdit] = useState({});
	const [params, setParams] = useState(
		Object.keys(props.row).map((item) => item)
	);

	const getEditedValue = (params, value) => {
		if (["emissao", "createdAt", "updatedAt"].includes(params))
			return getCorretData(value);
		if (params == "id_filial")
			return (
				user.filiaisList.data.find((item) => item.idFilial == value)
					?.identificacaoInterna || value
			);
		if (params == "romaneio") return String(value).padStart(7, "0");
		if (params == "peso_nf") return numerosEditadoBR(value);
		if (params == "valor_total") return valorMoeda(value);
		return value;
	};

	const changeValue = (e) => {
		
		setBodyEdit((last) => ({ ...last, [e.target.name]: e.target.value }));
	};

	const atualizarPedido = async () => {
		if (!bodyEdit) return;
		setBtn({ flag: true, msg: "Carregando", style: "warning" });

		if (bodyEdit.peso_nf) {
			bodyEdit.peso_nf = bodyEdit.peso_nf.replaceAll('.','').replaceAll(',','.')
		}
		if (bodyEdit.valor_total) {
			bodyEdit.valor_total = bodyEdit.valor_total.replaceAll('.','').replaceAll(',','.')
		}
		await props.fnRetorno(
			{ ...bodyEdit },
			{idProduto: body.id, idPedido: body.numero_pedido, idCarga: body.cargas_id, idFilial: body.id_filial}
		);
		setBtn({ flag: false, msg: "Salvo", style: "success" });

		setTimeout(() => {
			setBtn({ flag: false, msg: "Salvar", style: "primary" });
			props.setShow(false)
		}, 1000);
	};
	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>{props.title || "TITULO NÃO INFORMADO"}</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ overflow: "auto", margin: "0 20px" }}>
					<Form>
						<Row className="mb-2">
							<Col sm="3">Separado:</Col>
							<Col sm="3">
								<Form.Select defaultValue={body.conferido} name='conferido' onChange={changeValue}>
									<option></option>
									<option value={true}>Sim</option>
									<option value={false}>Não</option>
								</Form.Select>
							</Col>
						</Row> 	
						<Row className="mb-2">
							<Col sm="3">Planejado:</Col>
							<Col sm="3">
								<Form.Select defaultValue={body.planejado} name='planejado' onChange={changeValue}>
									<option ></option>
									<option value={true}>Sim</option>
									<option value={false}>Não</option>
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="3">Carregado:</Col>
							<Col sm="3">
								<Form.Select defaultValue={body.carregado} name='carregado' onChange={changeValue}>
									<option ></option>
									<option value={true}>Sim</option>
									<option value={false}>Não</option>
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="3">Descarregado:</Col>
							<Col sm="3">
								<Form.Select defaultValue={body.descarregado} name='descarregado' onChange={changeValue}>
									<option ></option>
									<option value={true}>Sim</option>
									<option value={false}>Não</option>
								</Form.Select>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer className="d-flex flex-row">
					<Button
						variant={btn.style}
						onClick={atualizarPedido}
						style={{ width: "fit-content", paddingInline: "40px" }}
					>
						{btn.msg}
						{btn.style == "success" ? <i className="bi bi-check"></i> : ""}
						{btn.style == "warning" ? (
							<Spinner size="sm" animation="border" color="black" />
						) : (
							""
						)}
					</Button>
					<Button
						variant="secondary"
						onClick={handlerSimpleClose}
						style={{ width: "fit-content", paddingInline: "40px" }}
					>
						Fechar
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
