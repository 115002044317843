import { createRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PlanoLositicaLayout from "../PlanoLositicaLayout";
import Loading from "../../loading/Loading";
import {
	update,
	updateCaminhoes,
	updateCaminhoesCaminhoeiros,
	updateCargasList,
} from "../../../redux/slicers/user";
import {
	Unauthorized,
	createCarga,
	getAllData,
	getCaminhoes,
	getTableColumns,
	verificaTipoUsuario,
} from "../../../services/Servicos";
import BodyRowCargas from "../../table/body/BodyRowCargas";
import HeadTable from "../../table/head/HeadRow";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import LoadingNew from "../../loading/LoadingNew";
import ModalError from "../../modal/ModalError";
import {
	filtroDinamicoArrays,
	getErrorMessage,
	isSuporte,
	removerDadosDuplicados,
} from "../../../services/helpers";
import { clearPersistData } from "../../../redux/storeConfig/store";
import ModalErrorPretty from "../../modal/ModalErrorPretty";

const tableRef = createRef();
const tbodyRef = createRef();
const rotaD = createRef();
const tipoCargaD = createRef();
const tipoTransporteD = createRef();
const formRef = createRef();
const propriedadeD = createRef(null);

export default function Cargas() {
	const cargas = useSelector((state) => state.userSlice.cargasList);
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(cargas.length ? false : true);
	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	const [headList, setHead] = useState(["index", "admin"]);
	const user = useSelector((state) => state.userSlice);

	const updateCaminhoesList = async () => {
		getCaminhoes(user.tipo).then((response) => {
			dispatch(updateCaminhoes(response));
		});
	};

	const updateArrayCabecalho = async () => {
		const cargasGet = await getTableColumns("Cargas");
		let headList = [
			"id",
			"id_filial",
			"nome",
			"status",
			"data_saida",
			"peso_total",
			"volume_total",
			"metros_quadrados",
			"qtde_pedidos",
			"qtde_produtos",
			"embalagens",
			"transferencias",
			"tipo_carga",
			"tipo_transporte",
			"regiao_entrega",
			"caminhao",
			"caminhoneiro",
			"observacao",
			...Object.keys(cargasGet.data)
				.filter((key) => key !== "data_saida")
				.filter((key) => key !== "regiao_entrega")
				.filter((key) => key !== "transferencias")
				.filter((key) => key !== "created_at")
				.filter((key) => key !== "updated_at")
				.filter((key) => key !== "qtde_pedidos")
				.filter((key) => key !== "qtde_produtos")
				.filter((key) => key !== "status")
				.filter((key) => key !== "tipo_transporte")
				.filter((key) => key !== "tipo_carga")
				.filter((key) => key !== "caminhao")
				.filter((key) => key !== "caminhoneiro")
				.filter((key) => key !== "observacao")
				.filter((key) => key !== "id")
				.filter((key) => key !== "nome")
				.filter((key) => key !== "peso_total")
				.filter((key) => key !== "metros_quadrados")
				.filter((key) => key !== "volume_total")
				.filter((key) => key !== "id_filial")
				.filter((key) => key !== "vale_refeicao")
				.filter((key) => key !== "valor_liberado")
				.filter((key) => key !== "cpf_caminhoneiro")
				.filter((key) => key !== "data_cancelamento")
				.filter((key) => key !== "data_abastecimento")
				.filter((key) => key !== "abastecimento_partida")
				.filter((key) => key !== "abastecimento_chegada")
				.filter((key) => key !== "cartao")
				.filter((key) => key !== "valor_cartao")
				.filter((key) => key !== "valor_dinheiro")
				.filter((key) => key !== "valor_pedagio")
				.filter((key) => key !== "valor_refeicao")
				.filter((key) => key !== "valor_")
				.filter((key) => key !== "id_abastecimento_partida")
				.filter((key) => key !== "empresas_id")
				.filter((key) => key !== "id_abastecimento_chegada")
				.filter((key) => key !== "liberacao_financeiro_file")
				.filter((key) => key !== "fechamento_financeiro_file")
				.filter((key) => key !== "id_filial")
				.filter((key) => key !== "cpf_responsavel")
				.filter((key) => key !== "data_chegada")
				.filter((key) => key !== "data_inicio_entrega")
				.filter((item) => item !== "created_at")
				.filter((item) => item !== "updated_at")
				.filter((item) => item !== "embalagens")
				.filter((item) => item !== "data_entrega"),
		];
		if (user.email.toUpperCase() == process.env.REACT_APP_EMAIL_SUPORTE) {
			setHead((last) => ["suporte", ...last, ...headList]);
		} else {
			setHead((last) => [...last, ...headList]);
		}
	};

	// scrolling
	useEffect(() => {
		const refTable = tableRef.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const divTableHeight = e.target.clientHeight;
			const scrollPosition = e.target.scrollTop;
			if (scrollPosition >= tbodyHeight - divTableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	// filters
	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let actualFilter = "";
		let lastFilter = "";
		let filter = "";
		let keywords = [];

		let paramsToFilter = {};

		filters.map((filterM, indexM) => {
			Object.keys(filterM).map((key) => {
				actualFilter = key;

				if (actualFilter !== lastFilter) {
					lastFilter = actualFilter;
					keywords = [];
				}

				filter = key;
				keywords.push(filterM[key]);
			});
			paramsToFilter[filter] = keywords;
		});

		setFilteredTable([]);
		let testeArray = [];

		Object.keys(paramsToFilter).map((key, _index) => {
			//
			paramsToFilter[key].map((value, index) => {
				// if (key === 'id_filial') {
				//     if (_index === 0) {
				//         testeArray.push(...cargas.data.filter(item => value.toLowerCase().includes(String(item[key]).toLowerCase())))
				//     } else if(_index === 1) {
				//         arrayFinal.push(...testeArray.filter((item => value.toLowerCase().includes(String(item[key]).toLowerCase()))))
				//     } else {
				//         arrayFinal2.push(...arrayFinal.filter((item => value.toLowerCase().includes(String(item[key]).toLowerCase()))))

				//     }
				//     return
				// }

				if (_index > 0) {
					const dados = testeArray.filter((item) =>
						String(item[key.toLocaleLowerCase()])
							.toLocaleLowerCase()
							.includes(value.toLocaleLowerCase())
					);
					if (dados.length && index === 0) {
						setFilteredTable((last) => [
							...last.filter((item) =>
								String(item[key.toLocaleLowerCase()])
									.toLocaleLowerCase()
									.includes(value.toLocaleLowerCase())
							),
						]);
					} else {
						setFilteredTable((last) => [
							...last,
							...testeArray.filter((item) =>
								String(item[key.toLocaleLowerCase()])
									.toLocaleLowerCase()
									.includes(value.toLocaleLowerCase())
							),
						]);
					}
				} else {
					// ]).toLocaleLowerCase().includes(value.toLocaleLowerCase())))
					const dados = cargas.data.filter((item) =>
						String(item[key.toLocaleLowerCase()])
							.toLocaleLowerCase()
							.includes(value.toLocaleLowerCase())
					);
					if (dados.length && index == 0) {
						testeArray.push(
							...cargas.data.filter((item) =>
								String(item[key.toLocaleLowerCase()])
									.toLocaleLowerCase()
									.includes(value.toLocaleLowerCase())
							)
						);
						setFilteredTable(testeArray);
					} else {
						setFilteredTable((last) => [...last, ...dados]);
					}
				}
			});
		});
		/* remover duplicatas */
		setFilteredTable((last) => removerDadosDuplicados(last, "name"));
	}, [filters]);


	const firstRender = async () => {
		
		let id_filial =
			user.tipo === "empresa" ? null : user.id_filial;
		
		if (user.id_filial == 2) {
			id_filial = null;
		}

		const [cabecalho, caminhoes, caminhoesCaminhoneiros, cargas] = await Promise.all([
			updateArrayCabecalho(),
			updateCaminhoesList(),
			getAllData(`caminhoes/caminhoneiros`)
				.then((resp) => {
					dispatch(updateCaminhoesCaminhoeiros(resp.data));
					setLoading(false);
				})
				.catch((error) => {
					if (error.response.status === 401) {
						clearPersistData();
						return window.location.replace("/login");
					}
				}),
			 getAllData( "cargas/em-processamento",{ id_filial, logica: "lte", status: 12 }).then(res => {
				dispatch(updateCargasList(res.data))
			})
		
		]);
	};

	useEffect(() => {
		firstRender();
	}, []);

	const [nomeCarga, setNomeCarga] = useState({
		cargas_id: "",
		nome: "",
		disponivel: false,
	});

	return (
		<PlanoLositicaLayout>
			<CadastrarCarga />
			<ListarCarga
				headList={headList}
				nomeCarga={nomeCarga}
				setNomeCarga={setNomeCarga}
				cargasR={cargas}
			/>
		</PlanoLositicaLayout>
	);
}

function CadastrarCarga() {
	const observacaoD = useRef();
	const nomeD = useRef();
	const rotas = useSelector((state) =>
		state.userSlice.rotasList.data
			.filter((i, n, s) => s.findIndex((o) => o.rota === i.rota) === n)
			.map((item) => item.rota)
	);
	const rotasSelect = useSelector((state) => state.userSlice.rotasList.data);
	const cidades = (rota) => {
		return rotasSelect
			.filter((item) => item.rota === rota)
			.filter((i, n, s) => s.indexOf(i) === n)
			.sort((a, b) => a.step - b.step)
			.map((item) => item.cidade)
			.join(", ");
	};

	const user = useSelector((state) => state.userSlice);
	const filiaisList = user.filiaisList.data;

	const [carga, setCarga] = useState({
		nome: "",
		observacao: "",
		rota: "",
		id_filial: user.tipo == "empresa" ? 2 : user.id_filial,
		tipo_carga: "PADRÃO",
		tipo_transporte: "PRÓPRIO",
		regiao_entrega: "LOCAL",
	});
	const [error, setError] = useState({ show: false, message: "" });
	const [showLoading, setShowLoading] = useState({
		show: false,
		msg: "Salvar",
		style: "primary",
	});
	const dispatch = useDispatch();

	const onChangeInputRoutes = (e) => {
		e.preventDefault();

		const cidadesL = cidades(e.target.value).split(",");
		setCarga((last) => ({
			...last,
			nome: `${cidadesL[0]} -${cidadesL[cidadesL.length - 1]}`,
			rota: e.target.value
		}));
	};
	const onChangeInputPadrao = (e) => {
		e.preventDefault();

		setCarga((last) => ({
			...last,
			[e.target.name]: e.target.value,
		}));
	};

	const updateArrayCargas = async () => {
		let url = `cargas`

		if (user.tipo != 'empresa' && !isSuporte(user.email)) {
			url+=`?id_filial=${user.id_filial}`
		}

		const cargas = await getAllData(url);
		dispatch(updateCargasList(cargas.data));
	};

	const submitHandler = (e) => {
		e.preventDefault();

		if (!carga.nome.length)
			return setError({
				show: true,
				message: "Nome da Carga não especificado",
			});

		if (!carga.id_filial)
			return setError({
				show: true,
				message: "Filial da carga não Especificada.",
			});

		setShowLoading({
			msg: "Salvando",
			style: "warning",
			show: true,
		});

		createCarga(
			{
				...carga,
				responsavel: String(user.name).toUpperCase(),
				cpf_responsavel: String(user.cpf).toUpperCase(),
				emissao: new Date(),
			},
			verificaTipoUsuario(user)
		)
			.then((response) => {
				setShowLoading({
					msg: "Salvo",
					style: "success",
					show: false,
				});
				setCarga({
					nome: "",
					observacao: "",
					rota: "",
					id_filial: user.tipo == "empresa" ? 2 : user.id_filial,
					tipo_carga: "PADRÃO",
					tipo_transporte: "PRÓPRIO",
					regiao_entrega: "LOCAL",
				});
				formRef.current.reset();

				if (!response.error) {
					updateArrayCargas();
					setTimeout(() => {
						setShowLoading({
							msg: "Salvar",
							style: "primary",
							show: false,
						});
					}, 500);
				}
			})
			.catch((error) => {
				setError({
					show: true,
					message: getErrorMessage(error),
				});

				setCarga((last) => ({
					...last,
					error: {
						flag: error.error,
						msg: error.msg,
					},
				}));

				setShowLoading({
					msg: "Error",
					style: "danger",
					show: false,
				});
				setTimeout(() => {
					setShowLoading({
						msg: "Salvar",
						style: "primary",
					});
				}, 1000);
			});
	};

	return (
		<div className="section-filters-table">
			<div className="card-header">
				<h3 style={{ display: "flex", alignItems: "center" }}>
					Cadastrar Cargas
				</h3>
			</div>
			<Form onSubmit={submitHandler} ref={formRef}>
				<Row className="align-items-end">
					<Col md="3" className="my-2">
						<Form.Label>Filial:</Form.Label>
						<Form.Select
							ref={propriedadeD}
							name="id_filial"
							disabled={user.tipo !== "empresa" ? true : false}
							value={carga.id_filial}
						>
							<option></option>
							{filiaisList.map((item, index) => (
								<option key={index} value={item.idFilial}>
									{item.identificacaoInterna}
								</option>
							))}
						</Form.Select>
					</Col>
					<Col md="3" className="my-2">
						<Form.Label>Nome Carga:</Form.Label>
						<Form.Control
							type="text"
							required
							name="nome"
							onChange={onChangeInputPadrao}
							value={carga.nome}
							disabled
						/>
					</Col>
					<Col md="3" className="my-2">
						<Form.Label>Observacao:</Form.Label>
						<Form.Control
							type="text"
							required
							value={carga.observacao}
							name="observacao"
							onChange={onChangeInputPadrao}
						/>
					</Col>
					<Col md="3" className="my-2">
						<Form.Label>Selecionar Rota:</Form.Label>
						<Form.Select type="text" required onChange={onChangeInputRoutes}>
							<option></option>
							{rotas.map((item, index) => (
								<option key={index} value={item}>
									{item}: {cidades(item)}{" "}
								</option>
							))}
						</Form.Select>
					</Col>
					<Col md="3" className="my-2">
						<Form.Label>Tipo de Carga:</Form.Label>
						<Form.Select
							type="text"
							required
							value={carga.tipo_carga}
							name="tipo_carga"
							onChange={onChangeInputPadrao}
						>
							<option value="PADRÃO">Padrão</option>
							<option value="TRANSFERÊNCIA">Transferência</option>
							<option value="RETORNO">Retorno</option>
						</Form.Select>
					</Col>
					<Col md="3" className="my-2">
						<Form.Label>Tipo de Transporte:</Form.Label>
						<Form.Select
							type="text"
							required
							value={carga.tipo_transporte}
							name="tipo_transporte"
							onChange={onChangeInputPadrao}
						>
							<option value="PRÓPRIO">Caminhão Próprio</option>
							<option value="FRETE">Caminhão Frete</option>
						</Form.Select>
					</Col>
					<Col md="3" className="my-2">
						<Form.Label>Região de Entrega:</Form.Label>
						<Form.Select
							type="text"
							required
							value={carga.regiao_entrega}
							name="regiao_entrega"
							onChange={onChangeInputPadrao}
						>
							<option value="LOCAL">Local</option>
							<option value="REGIONAL">Regional</option>
						</Form.Select>
					</Col>
				</Row>
				<Row className="justify-content-end">
					<Col sm="3" className="my-2">
						<Button
							variant={showLoading.style}
							disabled={showLoading.style !== "primary" ? true : false}
							onClick={submitHandler}
						>
							<LoadingNew
								show={showLoading.show}
								msg={showLoading.msg}
								style={showLoading.style}
								icone={""}
							/>
						</Button>
					</Col>
					<Col sm="3" className="my-2">
						<Button variant="secondary" type="reset">
							Apagar
						</Button>
					</Col>
				</Row>
			</Form>

			{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
		</div>
	);
}

export function ListarCarga({
	headList,
	height = 600,
	setNomeCarga = () => {},
	nomeCarga,
	cargasR = [],
}) {
	const cargas = useSelector((state) => state.userSlice.cargasList.data).filter(
		(item) => item.status < 13
	);
	const user = useSelector((state) => state.userSlice);
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);

	const [indexList, setIndexList] = useState(20);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	// const [cargaSelecionada, setNomeCarga] = useState({})
	const [caminhao, setCaminhao] = useState([]);
	const [condutores, setCondutores] = useState([]);
	const [filtersNew, setFiltersNew] = useState({});
	const [error, setError] = useState({
		show: false,
		message: "",
	});

	/* scrollbar */
	useEffect(() => {
		const refTable = tableRef.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const divTableHeight = e.target.clientHeight;
			const scrollPosition = e.target.scrollTop;
			if (scrollPosition >= tbodyHeight - divTableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	/* filtros */
	useEffect(() => {
		if (!filters.length) {
			setIndexList(20);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [cargas];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	const updateArrayCargas = async () => {
		let id_filial = user.id_filial;

		if (user.id_filial == 2 || user.tipo == 'empresa') {
			id_filial = null;
		}

		try {
			const cargasL = await getAllData( "cargas/em-processamento",{ id_filial, logica: "lt", status: 13 });
			// setCargas(cargasL.data)
			dispatch(updateCargasList(cargasL.data));
			setLoading(false)
		} catch (error) {
			setError({ show: true, message: getErrorMessage(error) });
		}

		// setCargas(cargas.data)
		setLoading(false);
	};

	useEffect(() => {
		updateArrayCargas();
	}, []);

	const selectCaminhao = (caminhao, caminhoneiro) => {
		if (!caminhao) {
			setCaminhao({});
			return;
		}
		const caminhaoSelecionado = user.caminhoesList.data.filter(
			(item) => item.placa === caminhao
		); //.includes(caminhao))
		const caminhoneiros = user.caminhoesCaminhoneirosList.data.filter(
			(item) =>
				item.cpf_caminhoneiro === caminhoneiro &&
				caminhoneiro &&
				item.caminhao_placa === caminhao
		);
		setCaminhao(caminhaoSelecionado);
		setCondutores(caminhoneiros);
	};

	useEffect(() => {
		selectCaminhao(nomeCarga?.caminhao, nomeCarga?.caminhoneiro);
		// setCaminhao()
	}, [nomeCarga]);

	return (
		<>
			<div className="section-filters-table">
				<div className="card-header">
					<h3>Filtros Selecionados</h3>
				</div>
				<Row className="mt-2 row-filters">
					<Col>
						<div>
							{filters.map((item, key) =>
								Object.keys(item).map((key, index) => {
									return (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">
													{key}: {item[key]}
												</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									);
								})
							)}
						</div>
					</Col>
				</Row>
			</div>
			<div className="section-filters-table">
				<div className="card-header">
					<h3 style={{ display: "flex", alignItems: "center" }}>
						Tabela de Cargas{" "}
						{loading ? (
							<Spinner
								style={{ marginLeft: "10px", fontSize: "1rem" }}
								animation="border"
								size="sm"
							/>
						) : (
							""
						)}
					</h3>
				</div>
				<Row>
					<Col>
						<ScrollbarLeft
							tableRef={tableRef}
							coluna2={4}
							maxima_distancia={150}
						/>
						<div
							className=" responsive-table tes"
							ref={tableRef}
							style={{ position: "relative", height, maxHeight: "unset" }}
						>
							{loading ? (
								""
							) : (
								<Table hover size="sm">
									<thead style={{ zIndex: "49" }}>
										<HeadTable
											arrayValues={[...cargas]}
											headerList={headList}
											setArrayFilters={setFiltersNew}
											arrayFilters={filtersNew}
											setFiltersOld={setFilters}
											filtersOld={filters}
										/>
									</thead>
									<tbody ref={tbodyRef}>
										{filteredTable.length
											? filteredTable.map((item, index, self) => {
													if (index < indexList) {
														return (
															<BodyRowCargas
																key={index}
																bodyList={item}
																headList={headList}
																index={index}
																setCargaSelecionada={setNomeCarga}
																tableLength={self.length}
															/>
														);
													}
											  })
											: [...cargas].reverse().map((item, index, self) => {
													if (index < indexList) {
														return (
															<BodyRowCargas
																key={index}
																bodyList={item}
																headList={headList}
																index={index}
																tableLength={self.length}
																setCargaSelecionada={setNomeCarga}
																setArrayFilters={setFiltersNew}
															/>
														);
													}
											  })}
									</tbody>
								</Table>
							)}
							{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
							{filteredTable ? (
								indexList < filteredTable.length ? (
									<Loading color={"black"} msg="Loading" />
								) : (
									""
								)
							) : indexList < cargas.length ? (
								<Loading color={"black"} msg="Loading" />
							) : (
								""
							)}
						</div>
					</Col>
				</Row>
			</div>

			<Informacoes caminhoes={caminhao} condutores={condutores} />

			{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
		</>
	);
}

function Informacoes({ caminhoes, condutores }) {
	return (
		<>
			{!caminhoes.length ? (
				""
			) : (
				<div className="mt-2">
					<h3 className="card-header">Informações da Carga</h3>
					<Row>
						{caminhoes &&
							caminhoes.map((item, key) => {
								return (
									<Col sm="4" md="6" lg="6" xl="4" key={key}>
										<Card
											className="card-dashboard"
											style={{ backgroundColor: "#F7D56C", padding: "10px" }}
										>
											<Card.Title className="text-center">
												Caminhão {key + 1}
											</Card.Title>
											<hr />
											<Row>
												<Col sm="4">Placa:</Col>
												<Col sm="4">{item.placa}</Col>
											</Row>
											<Row>
												<Col sm="4">Tipo:</Col>
												<Col sm="4">{item.tipo}</Col>
											</Row>
											<Row>
												<Col sm="4">Origem:</Col>
												<Col sm="4">{item.tipo_transporte}</Col>
											</Row>
											<Row>
												<Col sm="4">Comprimento:</Col>
												<Col sm="4">{item.comprimento}</Col>
												<Col sm="2">[m]</Col>
											</Row>
											<Row>
												<Col sm="4">Altura:</Col>
												<Col sm="4">{item.altura}</Col>
												<Col sm="2">[m]</Col>
											</Row>
											<Row>
												<Col sm="4">Largura:</Col>
												<Col sm="4">{item.largura}</Col>
												<Col sm="2">[m]</Col>
											</Row>
											<Row>
												<Col sm="4">Capacidade:</Col>
												<Col sm="4">{item.capacidade}</Col>
												<Col sm="2">[t]</Col>
											</Row>
											<Row>
												<Col sm="4">PBT:</Col>
												<Col sm="4">{item.pbt}</Col>
												<Col sm="2">[t]</Col>
											</Row>
											<Row>
												<Col sm="4">Tara:</Col>
												<Col sm="4">{item.tara}</Col>
												<Col sm="2">[t]</Col>
											</Row>
										</Card>
									</Col>
								);
							})}
						{condutores &&
							condutores.map((item, key) => {
								return (
									<Col sm="4" md="6" lg="6" xl="4" key={key}>
										<Card
											className="card-dashboard"
											style={{
												backgroundColor: "rgb(108 209 247)",
												padding: "10px",
											}}
										>
											<Card.Title className="text-center">
												Condutor {key + 1}
											</Card.Title>
											<hr />
											<Row>
												<Col sm="4">Condutor:</Col>
												<Col>{item.nome_caminhoneiro}</Col>
											</Row>
											<Row>
												<Col sm="4">CPF:</Col>
												<Col sm="4">{item.cpf_caminhoneiro}</Col>
											</Row>
											<Row>
												<Col sm="4">Telefone:</Col>
												<Col sm="4">{item.telefone_caminhoneiro}</Col>
											</Row>
										</Card>
									</Col>
								);
							})}
					</Row>
				</div>
			)}
		</>
	);
}
