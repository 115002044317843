import { Fragment, createRef, useCallback, useEffect, useRef, useState } from "react"
import { Button, Card, Col, Row, Spinner, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { cpf_cnpj_mask, getAllData, getCaminhoes, getDateInitMonth, getTableColumns, telefone_mask, updateTable, valorMoeda } from "../../services/Servicos"
import ScrollbarLeft from "../scrollbars/ScrollbarLeft"
import HeadTable from "../table/head/HeadRow"
import { filtroDinamicoArrays, getColorOfFilial, getColorOfStatusBool, removerDadosDuplicados, telefoneMask } from "../../services/helpers"
import ModalCriarFreteiro from "../modal/ModalCriarFreteiro"
import BodyRow from "../table/body/BodyRow"
import ModalLancamento from "./ModalLancamento"
import ModalCancelamento from "../frota/manutencao/ModalCancelamento"
import ModalEdicao from "../frota/manutencao/ModalEdicao"
import ModalError from "../modal/ModalError"
import ModalLancamentoChamados from "./ModalLancamentoChamados"


const tableRef = createRef()
const tbodyRef = createRef()


export default function ControleChamados() {
    return (
        <Fragment>
            <Card className='card-user'>
                <ListarDados />
            </Card>
        </Fragment>
    )
}

function ListarDados() {

    const [dados, setDados] = useState([])
    const [loading, setLoading] = useState(true)
    const [indexList, setIndexList] = useState(10)
    const [filteredTable, setFilteredTable] = useState(false)
    const [filters, setFilters] = useState([])
    const [lancarModal, setLancarModal] = useState({
        show: false
    })
	const [dataFiltro, setDataFiltro] = useState("");

    const [headList, setHead] = useState([])

    const getColumns = async () => {
        const header = await getTableColumns('ControleChamados')
        setHead([
            'index',
            'admin',
            ...Object
                .keys(header.data)
                .filter(key => key !== 'empresas_id')
                .filter(key => key !== 'created_at')
                .filter(key => key !== 'updated_at')
        ])
    }

    const getDados = async (data_inicial, data_final) => {
		setLoading(true);

		const [anoI, mesI, diaI] = data_inicial.split("-");
		const dataI = new Date(anoI, Number(mesI) - 1, diaI);
		const [anoF, mesF, diaF] = data_final.split("-");
		const dataF = new Date(anoF, Number(mesF) - 1, diaF);
		let objCons = {
			data_inicial,
			data_final,
		};

		setDataFiltro(
			`de ${dataI.toLocaleDateString()} até ${dataF.toLocaleDateString()}`
		);
        try {
            const response = await getAllData("controle-de-chamados",
                objCons
            );
            console.log(response)
            setDados(response.data)
            
        } catch (error) {
            console.log(error)
        } finally{
            setLoading(false);

        }

	};

    useEffect(() => {
        getColumns()
		const date = new Date();
		const dateCorrect = getDateInitMonth(date);
		getDados(dateCorrect.data_inicial, dateCorrect.data_final);
	}, []);
    useEffect(() => {
        if (!filters.length) {
            setIndexList(10)
            setFilteredTable(false)
            return
        }

        let testeFilters = {}

        filters.forEach(ele => {
            // testeFilters[arguments[ele]]
            const param = Object.getOwnPropertyNames(ele)[0]
            testeFilters[param] = []
        })

        filters.forEach(ele => {
            // testeFilters[arguments[ele]]
            const param = Object.getOwnPropertyNames(ele)[0]
            // testeFilters[param].push(ele[param])
            testeFilters[param].push(String(ele[param]).toLowerCase())

            if (String(ele[param]).toLowerCase() == 'false') {
                testeFilters[param].push('null')

            }
        })


        let arrayRetorno = [dados]

        Object.keys(testeFilters).forEach((key, index) => {
            arrayRetorno.push(filtroDinamicoArrays(testeFilters, key, arrayRetorno[arrayRetorno.length - 1],))
        })

        const arrayF = removerDadosDuplicados(arrayRetorno[arrayRetorno.length - 1], 'id')
        setFilteredTable(arrayF.reverse())

    }, [filters])

    useEffect(() => {
        const refTable = tableRef.current
        const scrollHandler = (e) => {
            const tbodyHeight = tbodyRef.current.clientHeight
            const divTableHeight = e.target.clientHeight
            const scrollPosition = e.target.scrollTop
            if (scrollPosition >= tbodyHeight - divTableHeight) {
                setTimeout(() => {
                    setIndexList(indexList + 10)
                }, 1000)
            }

        }

        refTable.addEventListener('scroll', scrollHandler, false)

        return () => refTable.removeEventListener('scroll', scrollHandler, false)
    })

    const onClickRemoveFilter = (e) => {
        e.preventDefault()
        const value = e.target.parentNode.firstElementChild.innerText
        const [key, keyValue] = value.split(':')
        setFilters(last => ([...last.filter(item => String(item[key]).replaceAll(' ', '') !== keyValue.replaceAll(' ', ''))]))

    }

    const [filtersNew, setFiltersNew] = useState({})

    useEffect(()=>{
        console.log(loading)
    },[loading])

    return (
        <Fragment>
            <div className="section-filters-table mb-5">
                <div className="card-header">
                    <h3>Filtros Selecionados</h3>
                </div>
                <Row className='mt-2 row-filters'>
                    <Col>
                        <div>
                            {
                                filters.map((item, key) =>
                                    Object
                                        .keys(item)
                                        .map((key, index) => {
                                            return <span className="filters-card" key={index}>
                                                <span className='filters'>
                                                    <span className='title'>
                                                        {key}: {item[key]}
                                                    </span>
                                                    <i className="bi bi-x-lg" onClick={onClickRemoveFilter}></i>
                                                </span>
                                            </span>
                                        })
                                )
                            }

                        </div>
                    </Col>
                </Row>
            </div>
            <div className="section-filters-table">
                <div className="card-header pb-2">
                    <Row className='justify-content-between'>
                        <Col sm='12' md='6' lg='8'>
                            <h3 style={{ display: 'flex', alignItems: 'center' }}>Tabela de Dados {loading ? <Spinner style={{ marginLeft: '10px', fontSize: '1rem' }} animation="border" size="sm" /> : ""}</h3>
                        </Col>
                        <Col sm='12' md='6' lg='2'>
                            <Button variant="dark" onClick={() => setLancarModal({show: true})}>Novo Chamado</Button>
                        </Col>
                    </Row>
                </div>
                <div className=" responsive-table" ref={tableRef} style={{minHeight:600}}>
                    <ScrollbarLeft tableRef={tableRef} />
                    <Table hover size='sm'>
                        <thead style={{ zIndex: 49 }}>
                            <HeadTable
                                arrayValues={dados}
                                headerList={headList} 
                                setArrayFilters={setFiltersNew} 
                                arrayFilters={filtersNew} 
                                setFiltersOld={setFilters} 
                                filtersOld={filters} 
                                fn={getDados}
                            />
                        </thead>
                        <tbody ref={tbodyRef}>

                            {
                                filteredTable ?
                                    filteredTable.map((item, index, self) => {
                                        if (index < indexList) {
                                            return <BodyRowLocal key={index} bodyList={item} headList={headList} index={index} tableLength={self.length} setDataTable={setDados} openModal={setLancarModal}/>
                                        }
                                    })
                                    :
                                    [...dados].reverse().map((item, index, self) => {
                                        if (index < indexList) {
                                            return <BodyRowLocal key={index} bodyList={item} headList={headList} index={index} tableLength={self.length} setDataTable={setDados} openModal={setLancarModal}/>
                                        }
                                    })
                            }

                        </tbody>
                    </Table>
                </div>
            </div>
            {lancarModal.show ? <ModalLancamentoChamados 
            setShow={setLancarModal}
            {...lancarModal} 
            fnRetorno={getDados} 
            /> : ""}
        </Fragment>
    )
}

function BodyRowLocal({ headList, bodyList, index, setDataTable = () => { }, tableLength = 0, openModal = () =>{}}) {
    const refRow = useRef(null)
    const userS = useSelector(state => state.userSlice)
    const filiaisList = userS.filiaisList.data

    const [controleCancelamento, setControleCancelamento] = useState({ falg: false })
    const [error, setError] = useState({
        flag: false,
        title: '',
        msg: '',
    })


    const getData = useCallback(async () => {
        const url = 'controle-de-mensagens'
        try {
            const data = await getAllData(url)
            setDataTable(data.data)

        } catch (error) {
            setError({
                flag: true, msg: error, title: 'Aviso!'
            })
        }
    })

    const onClickTableRow = (e) => {
        e.preventDefault()
        refRow.current.classList = 'bg-selected'
        let refRowNumber = refRow.current.children[0].innerText

        let i = refRowNumber
        let actualSibling = refRow.current.nextSibling

        while (i < tableLength) {
            if (actualSibling === null) {
                break
            }
            actualSibling.classList = ''

            actualSibling = actualSibling.nextSibling
            i++
        }

        i = refRow.current.children[0].innerText
        actualSibling = refRow.current.previousSibling
        while (0 < i) {
            if (actualSibling === null) {
                break
            }
            actualSibling.classList = ''
            actualSibling = actualSibling.previousSibling
            i--
        }
        // setCargaSelecionada({
        //     ...bodyList
        //     , disponivel: bodyList.status === 0 ? true : false
        // })

    }
    const STATUS = [
        {label: "ABERTO", classN:"0"},
        {label: "ENCERRADO", classN:"1"},
        {label: "RE-ABERTO", classN:"2"},
    ].find(item => item.label === bodyList.status).classN

    return (
        <Fragment>
            {
                <tr ref={refRow} style={{ verticalAlign: 'middle' }} onClick={onClickTableRow}>
                    {
                        headList.map((item, _index) => {

                            if (item === 'index') {
                                return <td key={_index}> {index + 1}</td>

                            }
                            else if (item === 'admin') {

                                return <td key={_index}>
                                    <div className="admin">
                                        <span className="editar" hidden={bodyList.status == '2'}>
                                            <i className="bi bi-pencil" title="Editar" onClick={() => openModal({show: true, chamado: bodyList})}/>
                                        </span>
                                    </div>
                                </td>
                            }
                            
                            else if (item === 'status') {

                                return <td key={_index} className={item}>
                                    <div className="div-status" style={{
										color: getColorOfStatusBool(STATUS).color,
										backgroundColor: getColorOfStatusBool(STATUS).bgColor,
										padding: '4px 6px',
										borderRadius: '4px',
										textAlign: 'center',
										boxShadow: '0 0 5px #0A061E80',
										border: '1px solid #E2E3DF80',
										width:'max-content'

									}}>
                                        {bodyList[item]}
                                    </div>
                                </td>

                            }
                            else if (['emissao','data_encerramento'].includes(item)) { /* primary key can not be modified */
                                return <td key={_index}>{bodyList[item] ? new Date(bodyList[item]).toLocaleString('pt-br') : ''}</td>
                            }
                            else if (['relato','solucao'].includes(item)) {

                                return <td key={_index} className={item}>
                                    <textarea rows={5} value={bodyList[item]}>
                                        
                                    </textarea>
                                </td>

                            }
                            return <td key={_index} className={item}> {bodyList[item]}</td>
                        })
                    }
                </tr>
            }

            {controleCancelamento.flag ? <ModalCancelamento {...controleCancelamento} setShow={setControleCancelamento} /> : ''}
            {error.flag ? <ModalError show={error.flag} setShow={setError} msg={error.msg} title={error.title} /> : ""}
        </Fragment>
    )
}