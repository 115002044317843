import { Fragment } from "react";
import { Nav } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

export default function NavbarSuporte() {
    const user = useSelector(state => state.userSlice)
    return (
        <Fragment>
            <Nav variant='tabs'>
                {
                    user.tipo.toLowerCase() === 'empresa' || user.tipo.toLowerCase() === 'gestor' ?
                        <Fragment>
                            <Nav.Item>
                                <NavLink className="nav-link" to='controle-mensagens'><i className="bi bi-whatsapp"></i> Controle de Mensagens</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="nav-link" to='mensagens-clientes'><i className="bi bi-whatsapp"></i> Mensagens aos Clientes</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="nav-link" to='chamados'><i className="bi bi-chat"></i> Chamados</NavLink>
                            </Nav.Item>
                        </Fragment>
                        :
                        ""
                }
            </Nav>
            <Outlet />
        </Fragment>
    )
}






